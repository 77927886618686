export default [
  {
    path: "/Reclutamiento",
    name: "Reclutamiento",
    redirect: "/Reclutamiento/ListaDeVacantes",
    component: () => import("@/views/public/Reclutamiento/ReclutamientoView.vue"),
    children: [
      {
        path: "ListaDeVacantes",
        name: "ListaDeVacantes",
        component: () => import("@/views/public/Reclutamiento/ListaDeVacantes"),
        children: [
          {
            path: ":id",
            name: "InformacionVacante",
            component: () => import("@/views/public/Reclutamiento/InformacionVacante"),
          },

        ]
      },
      {
        path: "FormularioDePostulacion/:vacanteId",
        name: "FormularioDePostulacion",
        component: () => import("@/views/public/Reclutamiento/FormularioDePostulacion"),
      },
      {
        path: "FormularioDePostulacion/:vacanteId/:aspiranteId",
        name: "FormularioSubDePostulacion",
        component: () => import("@/views/public/Reclutamiento/FormularioSubDePostulacion"),
        props: (route) => ({
          vacanteId: route.params.vacanteId,
          aspiranteId: route.params.aspiranteId,
        }),
      },
      {
        path: "CargarDocumentosDeSeleccion/:vacanteId/:postulacionId",
        name: "CargarDocumentosDeSeleccion",
        component: () => import("@/views/public/Reclutamiento/CargarDocumentosDeSeleccion"),
      },
      {
        path: "NavegacionAspirante",
        name: "NavegacionAspirante",
        component: () => import("@/views/public/Reclutamiento/NavegacionAspirante"),
      },
    ],
  },
];