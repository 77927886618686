export default {
    state: {
        tiposDeDocumentos: null,
        paises: null,
        departamentos: null,
        municipios: null,
        categoriaLicencia: null,
        nivelAcademico: null,
        parentescos: null,

        nivelDeCargo: null,
        cargosByEmpresa: null,
        sucursalesByEmpresa: null,
        departamentosByEmpresa: null,
        areasByEmpresa: null,
        tiposDeContrato: null,
        jornadasLaborales: null,
        // departamentosByPais: null,
        // municipiosByPais: null,

        personasDeLaEmpresa: null,
        tiposDeServicio: null,
        tiposDeVehiculo: null,
        tiposDeEnergiaEmpleada: null,
        legoPosiblesColaboradores: null,
        legoRoles: null
    },
    mutations: {
        setSourceData(state, { key, data }) {
            state[key] = data;
        },
    },
    actions: {
        getSourceData({ commit, getters }, { key, path, id = null, objectKey = null, valueKey = null }) {
            return new Promise((resolve, reject) => {
                getters.fetchGet({ path: id ? `${path}/${id}` : path })
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        }
                        throw new Error(response.status)
                    })
                    .then(data => {
                        if (objectKey && valueKey) {
                            data = data.map(item => ({ id: item[objectKey], name: item[valueKey] }))
                        }

                        commit('setSourceData', { key, data })
                        resolve(data)
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        },

        getTiposDeServicio({ state, dispatch }) {
            if (state.tiposDeServicio) return state.tiposDeServicio
            return dispatch('getSourceData', { key: 'tiposDeServicio', path: 'TiposData/deServicioVehiculo' })
        },

        getTiposDeVehiculo({ state, dispatch }) {
            if (state.tiposDeVehiculo) return state.tiposDeVehiculo
            return dispatch('getSourceData', { key: 'tiposDeVehiculo', path: 'TiposData/deVehiculo' })
        },

        getTiposDeEnergiaEmpleada({ state, dispatch }) {
            if (state.tiposDeEnergiaEmpleada) return state.tiposDeEnergiaEmpleada
            return dispatch('getSourceData', { key: 'tiposDeEnergiaEmpleada', path: 'TiposData/deEnergiaVehiculo' })
        },

        getTiposDeDocumentos({ state, dispatch }) {
            if (state.tiposDeDocumentos) return state.tiposDeDocumentos
            return dispatch('getSourceData', { key: 'tiposDeDocumentos', path: 'TiposData/deDocumento' })
        },
        getPaises({ state, dispatch }) {
            if (state.paises) return state.paises
            return dispatch('getSourceData', { key: 'paises', path: 'Pais/all' })
        },
        getDepartamentos({ state, dispatch }) {
            if (state.departamentos) return state.departamentos
            return dispatch('getSourceData', { key: 'departamentos', path: 'DepartamentoPais/GetList' })
        },
        getMunicipios({ state, dispatch }) {
            if (state.municipios) return state.municipios
            return dispatch('getSourceData', { key: 'municipios', path: 'Municipio/GetList' })
        },

        getNivelDeCargo({ state, dispatch }) {
            if (state.nivelDeCargo) return state.nivelDeCargo
            return dispatch('getSourceData', { key: 'nivelDeCargo', path: 'TipoCargo/TiposDeCargo' })
        },

        getCargosByEmpresa({ state, dispatch }, empresaId ) {
            if (state.cargosByEmpresa) return state.cargosByEmpresa
            return dispatch('getSourceData', { key: 'cargosByEmpresa', path: `Cargo/TodosLosCargosPorEmpresa/${empresaId}`, objectKey: 'id', valueKey: 'nombre' })
        },
        getSucursalesByEmpresa({ state, dispatch }, empresaId ) {
            if (state.sucursalesByEmpresa) return state.sucursalesByEmpresa
            return dispatch('getSourceData', { key: 'sucursalesByEmpresa', path: `Sucursal/ListSucursalEmpresa/${empresaId}` })
        },
        getDepartamentosByEmpresa({ state, dispatch }, empresaId ) {
            if (state.departamentosByEmpresa) return state.departamentosByEmpresa
            return dispatch('getSourceData', { key: 'departamentosByEmpresa', path: `Departamento/ListDepartamento/${empresaId}` })
        },

        getAreasByEmpresa({ state, dispatch }, empresaId ) {
            if (state.areasByEmpresa) return state.areasByEmpresa
            return dispatch('getSourceData', { key: 'areasByEmpresa', path: `Area/ListArea/${empresaId}` })
        },

        getTiposDeContrato({ state, dispatch }) {
            if (state.tiposDeContrato) return state.tiposDeContrato
            return dispatch('getSourceData', { key: 'tiposDeContrato', path: 'TiposData/decontrato' })
        },

        getJornadasLaborales({ state, dispatch }) {
            if (state.jornadasLaborales) return state.jornadasLaborales
            return dispatch('getSourceData', { key: 'jornadasLaborales', path: 'TiposData/dejornadalaboral' })
        },
        getLegoRoles({state, dispatch}) {
            if (state.legoRoles) return state.legoRoles
            return dispatch('getSourceData', { key: 'legoRoles', path: 'Lego/Roles' })
        },


        getSourceMapping({ commit, getters }, { key, path, id = null, objectKey = 'id', valueKey = 'description' }) {
            // Pensado para la tabla catalogo
            return new Promise((resolve, reject) => {
                getters.fetchGet({ path: id ? `${path}/${id}` : path })
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        }
                        throw new Error(response.status)
                    })
                    .then(result => {
                        let data = result
                        data = data.map(item => ({ id: item[objectKey], name: item[valueKey] }))
                        commit('setSourceData', { key, data })
                        resolve(data)
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        },

        getCategoriaLicencia({ state, dispatch }) {
            if (state.categoriaLicencia) return state.categoriaLicencia
            return dispatch('getSourceMapping', { key: 'categoriaLicencia', path: `ValuesCatalog/GetListByCodeCatalog/CategoriaLicencia` })
        },

        getNivelAcademico({ state, dispatch }) {
            if (state.nivelAcademico) return state.nivelAcademico
            return dispatch('getSourceMapping', { key: 'nivelAcademico', path: 'ValuesCatalog/GetListByCodeCatalog/NivelesAcademicos' })
        },

        getParentescos({ state, dispatch }) {
            if (state.parentescos) return state.parentescos
            return dispatch('getSourceMapping', { key: 'parentescos', path: 'ValuesCatalog/GetListByCodeCatalog/Parentesco' })
        },


        getSourceCallback({ commit, getters}, { key, path, id = null, callback }) {
            return new Promise((resolve, reject) => {
                getters.fetchGet({ path: id ? `${path}/${id}` : path })
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        }
                        throw new Error(response.status)
                    })
                    .then(result => {
                        let data = result
                        data = callback(data)
                        commit('setSourceData', { key, data })
                        resolve(data)
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        },

        getPersonasDeLaEmpresa({ state, dispatch }, empresaId) {
            if (state.personasDeLaEmpresa) return state.personasDeLaEmpresa
            return dispatch('getSourceCallback', { key: 'personasDeLaEmpresa', path: `Persona/ListPersonaByEmpresa/${empresaId}`, callback: data => data.map(item => ({
                    id: item.id,
                    text: `${item.nombres} ${item.apellidos}`,
                    identificador: item.numeroDocumento
                }))
            })
        },

        getLegoPosiblesColaboradores({state, dispatch}, empresaId) {
            if (state.legoPosiblesColaboradores) return state.legoPosiblesColaboradores
            return dispatch('getSourceCallback', { 
                key: 'legoPosiblesColaboradores', 
                path: `Persona/ListPersonaByEmpresa/${empresaId}`, 
                callback: data => data.map(item => ({
                    personaId: item.id,
                    name: `${item.nombres} ${item.apellidos}`,
                    email: item.correo,
                    roleId: null,
                }))
            })
        },


    },
    getters: {
        getSourceData(state) {
            return key => state[key];
        }
    }
}