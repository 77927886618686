<script>
import { KEY_ASSISTANT_PESV, KEY_ASSISTANT_SGSST, KEY_ASSISTANT_LABORAL } from './constKeys'

const authorizedEmails = [
  'Diego.rojas@helexium.com',
  'administrador@helexium.com',
  'Cesar@helexium.com',
  'Luis.rapalino@helexium.com',
  'Abraham.sanchez@helexium.com',
  'Sergio.alonso@helexium.com',
  'Sebastian.diaz@helexium.com',
  'admin0@gse.com',
  'admin0@umng.com',
  'admin0@MovLife.co',
  'admin0@tusdatos.co',
  'admin0@escueladelogistica.com',
  'admin0@centromayor.com',
  'admin0@impulsohelexium.com',
  'admin0@gescomex.com',
  'admin0@abc.com',
  'admin0@impulsoagencia.com',
  'admin0@helexiumanalytics.com',
  'admin0@uniox.com'
]


export default {
  components: {
    ChatResultadosEvaluacion: () => import('@/views/Helexium/Assistant/ChatResultadosEvaluacion.vue'),
    ChatAssistant: () => import('@/views/Helexium/Assistant/ChatAssistant.vue'),
    CloseButton: () => import('@/components/buttons/AddBtn.vue'),
    ChatFavoritos: () => import('@/views/Helexium/Assistant/ChatFavoritos.vue')
  },
  mounted() {
    const indexSaved = localStorage.getItem('chatAISelectedIndex')
    if (indexSaved) {
      this.chatSelected = parseInt(indexSaved)
    }
  },
  data() {
    return {
      listChats: [
        {
          component: 'ChatResultadosEvaluacion',
          title: 'Resultados de evaluación',
          name: 'resultadosEvaluacion',
          iconPublicPath: '/icons-dashboard/icono_evaluaciones.png'
        },
        {
          component: 'ChatAssistant',
          title: 'Asistente PESV',
          name: 'Asistente PESV',
          iconPublicPath: '/icons-dashboard/icono_seguridad_vial.png',
          props: {
            title: 'Asistente PESV',
            assistantId: KEY_ASSISTANT_PESV
          }
        },
        {
          component: 'ChatAssistant',
          title: 'Asistente SGSST',
          name: 'Asistente SGSST',
          iconPublicPath: '/icons-dashboard/evaluacion.png',
          props: {
            title: 'Asistente SGSST',
            assistantId: KEY_ASSISTANT_SGSST
          }
        },
        {
          component: 'ChatAssistant',
          title: 'Asistente Laboral',
          name: 'Asistente Laboral',
          iconPublicPath: '/icons-dashboard/soporte.png',
          props: {
            title: 'Asistente Laboral',
            assistantId: KEY_ASSISTANT_LABORAL
          }
        },
        {
          component: 'ChatFavoritos',
          title: 'Favoritos',
          name: 'Favoritos',
          iconPublicPath: '/icons-dashboard/PhStarDuotone.svg'
        }
      ],
      chatSelected: 0,
      reactiveInSaveComponent: true
    }
  },
  computed: {
    imShowing() {
      return this.$store.state.modalChatIA
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    listChatsFilter() {
      return this.listChats.filter(({ name }) => {
        if (name == 'resultadosEvaluacion') {
          const { correo } = this.userLoggedIn
          return authorizedEmails.includes(correo)
        }
        return true
      })
    }
  },
  watch: {
    chatSelected(currency) {
      localStorage.setItem('chatAISelectedIndex', currency)

      // como se usa el mismo componente para algunos chats, se debe reiniciar el componente
      this.reactiveInSaveComponent = false
      this.$nextTick(() => {
        this.reactiveInSaveComponent = true
      })
    }
  }
}
</script>

<template>
  <section class="wrapper-chat" :class="{ active: imShowing }">
    <header class="header">
      <ul class="chats-nav">
        <li class="item-chat" :class="{ active: chatSelected === index }"
          v-for="({ iconPublicPath, title }, index) in listChatsFilter" :key="title" @click="chatSelected = index"
          :title="title">
          <img v-if="iconPublicPath" :src="iconPublicPath" :alt="title">
        </li>
      </ul>
      <CloseButton class="close" @click="$store.commit('showChatIA', false)" />
    </header>

    <div class="chats-view">
      <component v-if="reactiveInSaveComponent" :is="listChatsFilter[chatSelected].component"
        v-bind="listChatsFilter[chatSelected].props" />
    </div>

  </section>
</template>

<style>
:root {
  --square-size-icon-chat: 32px;
}
</style>

<style scoped>
.wrapper-chat {
  padding: .5rem;
  position: fixed;
  bottom: 0;
  right: 0;
  min-width: 300px;
  width: 30vw;
  height: 100vh;
  background-color: rgb(20, 20, 20);
  /* filter: blur(10px); */
  z-index: 99999 !important;
  display: flex;
  flex-direction: column;
}

.header {
  height: 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-chat {
  height: var(--square-size-icon-chat);
  width: var(--square-size-icon-chat);
  padding: 2px;
  cursor: pointer;
}

.item-chat img {
  object-fit: cover;

}

.item-chat:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.item-chat.active {
  background-color: #050505;
}

.chats-view {
  height: 95%;
}


.chats-nav {
  padding: 0;
  display: flex;
  gap: .25rem;
  margin: 0;
}

.wrapper-chat.active {
  transform: translateX(0%);
  visibility: visible;
}

.wrapper-chat {
  transition: .3s;
  transform: translateX(100%);
  visibility: hidden;
}
</style>