const store = {
  namespaced: true,
  state: {
    sucursales: [],
    departamentos: [],
    tiposCargos: [],
    cargos: [],
    layout: 1,
    activeFase: 0,
  },
  mutations: {
    SET_ACTIVE_FASE(state, index) {
      state.activeFase = index;
    },
    setLayout(state) {
      state.layout = state.layout === 1 ? 2 : 1;
    },
    setSucursales(state, data) {
      state.sucursales = data;
    },
    setDepartamentos(state, data) {
      state.departamentos = data;
    },
    setTiposCargos(state, data) {
      state.tiposCargos = data;
    },
    setCargos(state, data) {
      state.cargos = data;
    },
  },
  actions: {
    setActiveFase({ commit }, index) {
      commit("SET_ACTIVE_FASE", index);
    },
    changeLayout({ commit }) {
      commit("setLayout");
    },

    async getSucursales({ rootGetters, commit }) {
      const query = `
        SELECT SucursalId, sucursal, AVG(Valoracion) AS PromedioValoracion
        FROM ConsultaResultadosEvaluacionesV2
        GROUP BY SucursalId, sucursal;
      `;
      const empresaId = rootGetters.userLoggedIn.empresa.id;
      try {
        const response = await rootGetters.fetchPost({
          path: `ConsultaResultadosEvaluacionesV2/query/${empresaId}`,
          data: { query },
        });
        if (response.ok) {
          const allData = await response.json();
          commit("setSucursales", allData.data);
        } else {
          console.error("Error en la respuesta del servidor", response);
        }
      } catch (error) {
        console.error("Error en getSucursales:", error);
      }
    },

    async getDepartamentos({ rootGetters, commit }, sucursalId = "") {
      const query = `
        SELECT SucursalId, DepartamentoId, departamento, AVG(Valoracion) AS PromedioValoracion
        FROM ConsultaResultadosEvaluacionesV2
        ${sucursalId !== "" ? `WHERE SucursalId = '${sucursalId}'` : ""}
        GROUP BY SucursalId, DepartamentoId, departamento;
      `;
      const empresaId = rootGetters.userLoggedIn.empresa.id;
      try {
        const response = await rootGetters.fetchPost({
          path: `ConsultaResultadosEvaluacionesV2/query/${empresaId}`,
          data: { query },
        });
        if (response.ok) {
          const allData = await response.json();
          commit("setDepartamentos", allData.data);
        } else {
          console.error("Error en la respuesta del servidor", response);
        }
      } catch (error) {
        console.error("Error en getDepartamentos:", error);
      }
    },

    async getTiposCargos({ rootGetters, commit }, departamentoId = "") {
      const query = `
        SELECT  TipoCargoId, TipoCargo, AVG(Valoracion) AS PromedioValoracion
        FROM ConsultaResultadosEvaluacionesV2      
        ${
          departamentoId !== ""
            ? `WHERE DepartamentoId = '${departamentoId}'`
            : ""
        }
        GROUP BY  TipoCargoId, TipoCargo;
      `;
      const empresaId = rootGetters.userLoggedIn.empresa.id;
      try {
        const response = await rootGetters.fetchPost({
          path: `ConsultaResultadosEvaluacionesV2/query/${empresaId}`,
          data: { query },
        });
        if (response.ok) {
          const allData = await response.json();
          commit("setTiposCargos", allData.data);
        } else {
          console.error("Error en la respuesta del servidor", response);
        }
      } catch (error) {
        console.error("Error en getTiposCargos:", error);
      }
    },

    async getCargos({ rootGetters, commit }, departamentoId = "") {
      const query = `
        SELECT CargoId, cargo, AVG(Valoracion) AS PromedioValoracion
        FROM ConsultaResultadosEvaluacionesV2
        ${
          departamentoId !== ""
            ? `WHERE DepartamentoId = '${departamentoId}'`
            : ""
        }
        GROUP BY CargoId, cargo;
      `;
      const empresaId = rootGetters.userLoggedIn.empresa.id;
      try {
        const response = await rootGetters.fetchPost({
          path: `ConsultaResultadosEvaluacionesV2/query/${empresaId}`,
          data: { query },
        });
        if (response.ok) {
          const allData = await response.json();
          commit("setCargos", allData.data);
        } else {
          console.error("Error en la respuesta del servidor", response);
        }
      } catch (error) {
        console.error("Error en getCargos:", error);
      }
    },
  },
};

export default store;
