import data from "./fichaCuestionarioSubindicadores";

export default {
  state: {
    formSiniestros: null,
    formAdministrativo: null,
    formFuncionamiento: null,
    formVehiculos: null,
  },
  mutations: {
    initFormsFase2(state) {
      state.formSiniestros = data.formSiniestros;
      state.formAdministrativo = data.formAdministrativo;
      state.formFuncionamiento = data.formFuncionamiento;
      state.formVehiculos = data.formVehiculos;
    },
    updateFormsFase2(state, formData) {
      // Función auxiliar para actualizar los indicadores de un formulario
      const updateForm = (form, data) => {
        form.indicadores = form.indicadores.map((x) => ({
          ...x,
          value: data.length ? data[0][x.name] : "",
        }));
        form.evidencias = form.evidencias.map((x) => ({
          ...x,
          value: data.length ? data[0][x.name] : "",
        }));
      };

      // Actualizar cada formulario en el estado
      updateForm(state.formSiniestros, formData.Siniestros);
      updateForm(state.formAdministrativo, formData.Administrativo);
      updateForm(state.formFuncionamiento, formData.Funcionamiento);
      updateForm(state.formVehiculos, formData.Vehiculos);
    },
  },
  actions: {
    initFormsFase2({ commit }) {
      try {
        commit("initFormsFase2");
      } catch (error) {
        console.log("err", error);
      }
    },
    updateFormsFase2({ commit }, data) {
      try {
        commit("updateFormsFase2", data);
      } catch (error) {
        console.log("err", error);
      }
    },
  },
  getters: {},
};
