import Vue from "vue";
import VueRouter from "vue-router";
import publicViews from "./publicViews";
import userLoggedIn from "./middleware/userLoggedIn";

const SignIn = () => import("@/views/backend/Auth/SignIn");
const HojaDeVidaVehiculo = () =>
  import("@/views/Helexium/HojaDeVidaVehiculo/HojaDeVidaVehiculo");
const IndicadoresPESV = () =>
  import("@/views/Helexium/IndicadoresPESV/IndicadoresPESV");
const InspeccionVehiculo = () =>
  import("@/views/Helexium/InspeccionVehiculo/InspeccionVehiculo");
const RegistroDeVehiculos = () =>
  import("@/views/Helexium/RegistroDeVehiculos/RegistroDeVehiculos");

const SGSST = () => import("@/views/Helexium/SGSST/SGSST");
const SGSSTProfesiograma = () => import("@/views/Helexium/SGSST/Profesiograma");
// const SGSSTInspeccionElementos = () =>
//   import("@/views/Helexium/SGSST/InspeccionElementos");
// const SGSSTListarInspeccionElementos = () =>
//   import("@/views/Helexium/SGSST/ListarInspeccionElementos");
const SGSSTListarProfesiograma = () => import("@/views/Helexium/SGSST/ListarProfesiograma");
const SGSSTInspeccionElementos = () => import("@/views/Helexium/SGSST/InspeccionElementos");
const SGSSTListarInspeccionElementos = () => import("@/views/Helexium/SGSST/ListarInspeccionElementos");



const PESV = () => import("@/views/Helexium/PESV/PESV");

const PeligrosYRiesgos = () =>
  import("@/views/Helexium/PeligrosYRiesgos/PeligrosYRiesgos");
const SGSSTFichaIndicadores = () =>
  import("@/views/Helexium/SGSSTFichaIndicadores/SGSSTFichaIndicadores");
const PESVFichaIndicadores = () =>
  import("@/views/Helexium/PESVFichaIndicadores/PESVFichaIndicadores");

const Pendientes = () => import("@/views/Helexium/Evaluaciones/Pendientes");
const Finalizadas = () => import("@/views/Helexium/Evaluaciones/finalizadas");

const CaracterizacionEnfermedadLaboral = () =>
  import(
    "@/views/Helexium/CaracterizacionEnfermedadLaboral/CaracterizacionEnfermedadLaboral"
  );

const Empresa = () => import("@/views/Helexium/Empresas/Empresa");
const Empresas_agregar = () =>
  import("@/views/Helexium/Empresas/Empresas_agregar");
const Empresas_editar = () =>
  import("@/views/Helexium/Empresas/Empresas_editar");
const Persona = () => import("@/views/Helexium/Persona/Persona");
const Persona_agregar = () =>
  import("@/views/Helexium/Persona/Persona_agregar");
const Persona_historial_laboral = () =>
  import("@/views/Helexium/Persona/Persona_historial_laboral");
const Persona_editar = () => import("@/views/Helexium/Persona/Persona_editar");
const Sucursales = () => import("@/views/Helexium/Sucursales/Sucursales");
const Sucursales_agregar = () =>
  import("@/views/Helexium/Sucursales/Sucursales_agregar");
const Sucursales_editar = () =>
  import("@/views/Helexium/Sucursales/Sucursales_editar");

const Departamentos = () =>
  import("@/views/Helexium/Departamentos/Departamentos");
const Departamentos_agregar = () =>
  import("@/views/Helexium/Departamentos/Departamentos_agregar");
const Departamentos_editar = () =>
  import("@/views/Helexium/Departamentos/Departamentos_editar");

const Areas = () => import("@/views/Helexium/Areas/Areas");
const Areas_agregar = () => import("@/views/Helexium/Areas/Areas_agregar");
const Areas_editar = () => import("@/views/Helexium/Areas/Areas_editar");
const Competencias = () => import("@/views/Helexium/Competencias/Competencias");
const Competencias_agregar = () =>
  import("@/views/Helexium/Competencias/Competencias_agregar");
const Competencias_editar = () =>
  import("@/views/Helexium/Competencias/Competencias_editar");
const RegistroDeAccidentes = () =>
  import("@/views/Helexium/RegistroDeAccidentes/RegistroDeAccidentes");

const Enviar_medallas = () =>
  import("@/views/Helexium/PerfilMedallas/Enviar_medallas");

const dashboard_nuevo = () =>
  import("@/views/Helexium/dashboard_nuevo/dashboard_nuevo");

const RegistroDeIncapacidades = () =>
  import("@/views/Helexium/RegistroDeIncapacidades/RegistroDeIncapacidades");

// preguntas
const Preguntas = () => import("@/views/Helexium/Preguntas/Preguntas");
const Preguntas_agregar = () =>
  import("@/views/Helexium/Preguntas/Preguntas_agregar");
const Preguntas_lista = () =>
  import("@/views/Helexium/Preguntas/Preguntas_lista");

// cargos
const Cargos = () => import("@/views/Helexium/Cargos/Cargos");
const Cargos_agregar = () => import("@/views/Helexium/Cargos/Cargos_agregar");
const Cargos_editar = () => import("@/views/Helexium/Cargos/Cargos_editar");
const Cargos_perfil = () => import("@/views/Helexium/Cargos/Cargos_perfil");

const hoja_vida = () => import("@/views/Helexium/Mi_perfil/hoja_vida");

// medallas
const Medallas = () => import("@/views/Helexium/Medallas/Medallas");
const Medallas_agregar = () =>
  import("@/views/Helexium/Medallas/Medallas_agregar");
const Medallas_editar = () =>
  import("@/views/Helexium/Medallas/Medallas_editar");

// reportes
const Reportes = () => import("@/views/Helexium/Reportes/Reportes");
const Reportes_agregar = () =>
  import("@/views/Helexium/Reportes/Reportes_agregar");
const Reportes_editar = () =>
  import("@/views/Helexium/Reportes/Reportes_editar");

// perfil cargo
const PerfilCargo = () => import("@/views/Helexium/PerfilCargo/PerfilCargo");
const PerfilMedallas = () =>
  import("@/views/Helexium/PerfilMedallas/PerfilMedallas");
const PerfilComentarios = () =>
  import("@/views/Helexium/PerfilComentarios/PerfilComentarios");

const Tipo_Competencias = () =>
  import("@/views/Helexium/tipos/Tipo_Competencias");
const Tipo_Identidad = () => import("@/views/Helexium/tipos/Tipo_Identidad");
const Tipo_Niveles_cargo = () =>
  import("@/views/Helexium/tipos/Tipo_Niveles_cargo");

const Ciclo_evaluaciones = () =>
  import("@/views/Helexium/Evaluaciones/Ciclo_evaluaciones");
const Ciclo_evaluaciones_editar = () =>
  import("@/views/Helexium/Evaluaciones/Ciclo_evaluaciones_editar");
const Ciclo_evaluaciones_agregar = () =>
  import("@/views/Helexium/Evaluaciones/Ciclo_evaluaciones_agregar");
const evaluacion = () => import("@/views/Helexium/Evaluaciones/evaluaciones");

//academia y cursos
const AcademiaVideos = () =>
  import("@/views/Helexium/Ayuda/Academia/AcademiaVideos");
const Cursos = () => import("@/views/Helexium/Ayuda/Academia/Cursos");
const InfoCurso = () =>
  import("@/views/Helexium/Ayuda/Academia/InfoCurso/InfoCurso");
const SoporteVideos = () =>
  import("@/views/Helexium/Ayuda/Soporte/SoporteVideos");
const VideoManager = () =>
  import("@/views/Helexium/Ayuda/AdministradorDeVideo/VideoManager");

const AdministradorDeVacantes = () =>
  import("@/views/Helexium/Vacantes/AdministradorDeVacantes");
const NuevaVacante = () => import("@/views/Helexium/Vacantes/NuevaVacante");
const EditarVacante = () => import("@/views/Helexium/Vacantes/EditarVacante");

const ListaDePostulantes = () =>
  import("@/views/Helexium/Postulantes/ListaDePostulantes");

const ProcesoDePreseleccion = () =>
  import("@/views/Helexium/ReclutamientoYSeleccion/ProcesoDePreseleccion");
const NuevaPostulacion = () =>
  import("@/views/Helexium/Postulantes/NuevaPostulacion");
const RegistroDePostulacion = () =>
  import("@/views/Helexium/Postulantes/RegistroDePostulacion");

const ProcesoDeSeleccion = () =>
  import("@/views/Helexium/ReclutamientoYSeleccion/ProcesoDeSeleccion");

const EvaluarDocumentosDeSeleccion = () =>
  import(
    "@/views/Helexium/ReclutamientoYSeleccion/EvaluarDocumentosDeSeleccion"
  );
const VinculacionDePostulantes = () =>
  import("@/views/Helexium/ReclutamientoYSeleccion/VinculacionDePostulantes");

//Data lake
const DataLake = () => import("@/views/Helexium/DataLake/dataLake");
const ReadXlsFile = () => import("@/views/Helexium/SuperAdmin/ReadXlsFile");

//NOMINA

const Honorarios = () =>
  import("@/views/Helexium/Nomina/Honorarios/Honorarios");

// const Honorarios = () =>
//   import("@/views/Helexium/Nomina/Honorarios/Honorarios");

const Numeraciones = () =>
  import("@/views/Helexium/Nomina/Numeracion/Numeraciones");
const Numeracion_agregar = () =>
  import("@/views/Helexium/Nomina/Numeracion/Numeracion_agregar");
const Numeracion_editar = () =>
  import("@/views/Helexium/Nomina/Numeracion/Numeracion_editar");

const Devengados = () => import("@/views/Helexium/Nomina/Devengado/Devengados");
const Deducciones = () =>
  import("@/views/Helexium/Nomina/Deduccion/Deducciones");
const Nominas = () => import("@/views/Helexium/Nomina/Nomina/Nomina");
const Nomina_Editar = () =>
  import("@/views/Helexium/Nomina/Nomina/Nomina_Editar");
const Liquidacion = () =>
  import("@/views/Helexium/Nomina/Liquidacion/Liquidacion");
const Liquidacion_Agregar = () =>
  import("@/views/Helexium/Nomina/Liquidacion/Liquidacion_Agregar");
const Liquidacion_Editar = () =>
  import("@/views/Helexium/Nomina/Liquidacion/Liquidacion_Editar");
const LiquidacionEditarPrimaServicios = () =>
  import("@/views/Helexium/Nomina/Liquidacion/Liquidacion_Editarv2");
const liquidacioneditardetalle = () =>
  import("@/views/Helexium/Nomina/Liquidacion/Liquidacion_EditarDetalle");
const liquidacioneditardetallev2 = () =>
  import("@/views/Helexium/Nomina/Liquidacion/Liquidacion_EditarDetalleV2");

const RegistroNovedades = () =>
  import("@/views/Helexium/Nomina/RegistroNovedades/RegistroNovedades");

const AutorizacionFunciones = () =>
  import("@/views/Helexium/SuperAdmin/ModalFunciones");
const TerminosYCondiciones = () =>
  import("@/views/Helexium/SuperAdmin/TerminosYCondiciones");

// Plan de Mejoramiento👇
const PlanMejoramiento = () =>
  import("@/views/Helexium/PlanMejoramiento/PlanMejoramiento");
const PlanMejoramiento_agregar = () =>
  import("@/views/Helexium/PlanMejoramiento/PlanMejoramiento_agregar");
// const PlanMejoramiento_finalizados = () =>
//   import("@/views/Helexium/PlanMejoramiento/PlanMejoramiento_finalizados");
// Plan de Mejoramiento☝︝

// mis actividades👇
const MisActividades = () =>
  import("@/views/Helexium/MisActividades/MisActividades");
// mis actividades☝︝

// Onboarding👇
const Onboarding = () => import("@/views/Helexium/Onboarding/Onboarding");
const Onboarding_admin = () =>
  import("@/views/Helexium/Onboarding/Onboarding_admin");
const Onboarding_agregar = () =>
  import("@/views/Helexium/Onboarding/Onboarding_agregar");

// Onboarding☝︝

// Capacitaciones👇
const RegistroCapacitaciones = () =>
  import("@/views/Helexium/Capacitaciones/RegistroCapacitaciones");
const RegistroCapacitaciones_agregar = () =>
  import("@/views/Helexium/Capacitaciones/RegistroCapacitaciones_agregar");
const MisCapacitaciones = () =>
  import("@/views/Helexium/Capacitaciones/MisCapacitaciones");

// apacitaciones☝︝

// GPS
const RegistroImeiGPS = () => import("@/views/Helexium/GPS/RegistroImeiGPS");
const gps_agregar = () => import("@/views/Helexium/GPS/gps_agregar");

// registro servicios
const RegistroServicios = () =>
  import("@/views/Helexium/RegistroServicios/RegistroServicios");

// // const dashboard_nuevo_prueba = () => import('@/views/Helexium/dashboard_nuevo_prueba/dashboard_nuevo_prueba')
// //Adding layouts router.
// // const BlankLayout = () => import("@/layouts/BlankLayout")

// const Layout10 = () => import("@/layouts/backend/Layout-10");
// const Layout10 = () => import("@/layouts/backend/Layout-9");

//Tickets

const Chat = () => import("@/views/backend/App/Mail/EmailListing");

const Tickets = () => import("@/views/Helexium/Tickets/ListarTickets");
const AgregarTickets = () => import("@/views/Helexium/Tickets/AgregarTickets");
const ChatTicket = () => import("@/views/Helexium/Tickets/ChatTicket");

const GestionAccesos = () =>
  import("@/views/Helexium/GestionAccesos/GestionAccesos");

const Map = () => import("@/views/Helexium/SuperAdmin/Map");

const MapGps = () => import("@/views/Helexium/SuperAdmin/MapGps");

// market and canjes
const Canjes = () => import("@/views/Helexium/Market/Canjes");
const Market = () => import("@/views/Helexium/Market/Market");
const ManagerMarket = () => import("@/views/Helexium/Market/ManagerMarket");

//Historial Clinico

//Medico Ocupacional
const MedOcuLaboral = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuLaboral");
const MedOcuEventos = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuEventos");
const MedOcuFamiliar = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuFamiliar");
const MedOcuPersonal = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuPersonal");
const MedOcuHabitos = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuHabitos");
const MedOcuFisico = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuFisico");
const MedOcuOtros = () =>
  import("@/views/Helexium/HistorialClinico/MedicoOcupacional/MedOcuOtros");
7;
//Prueba Charts
const ReportesGraficos = () =>
  import("@/views/Helexium/ReportesConApex/ReporteGraficos");
const ReportePerfilCargo = () =>
  import("@/views/Helexium/ReportesConApex/ReportePerfilCargo");
const ReporteRegistros = () =>
  import("@/views/Helexium/ReportesConApex/ReporteRegistros");

const NotFound = () => import("@/views/Helexium/_404");
const Terminales = () => import("@/views/Helexium/Terminales");

const ContainerEncuesta = () =>
  import("@/views/Helexium/PESV/ContainerEncuesta");

const CategorizacionPESV = () =>
  import("@/views/Helexium/PESV/Categorizacion/Categorizacion");

Vue.use(VueRouter);

const helexiumRoutes = () => [
  // categorizacion pesv
  {
    path: "PESV/Categorizacion",
    name: "CategorizacionPESV",
    meta: { name: "CategorizacionPESV" },
    component: CategorizacionPESV,
  },
  // Encuestas pesv
  {
    path: "PESV/encuestas/:idEncuesta",
    name: "ContainerEncuesta",
    meta: { name: "ContainerEncuesta" },
    component: ContainerEncuesta,
    props: (route) => ({
      idEncuesta: route.params.idEncuesta,
      idVigilado: route.query.vigilado,
      numeroReporte: route.query.reporte,
    }),
  },
  //Graficos
  {
    path: "ReportesConApex/Graficos",
    name: "Graficos",
    meta: { name: "Graficos" },
    component: ReportesGraficos,
  },
  {
    path: "ReportesConApex/PerfilCargo",
    name: "PerfilCargo",
    meta: { name: "PerfilCargo" },
    component: ReportePerfilCargo,
  },
  {
    path: "ReportesConApex/Registros",
    name: "Registros",
    meta: { name: "Registros" },
    component: ReporteRegistros,
  },
  //Graficos
  {
    path: "MiAvatar",
    name: "MiAvatar",
    component: () => import("@/views/Helexium/MiAvatar/MiAvatar"),
  },
  {
    path: "terminales",
    name: "Terminales",
    component: Terminales,
  },
  {
    path: "CreacionDeAvatar",
    name: "CreacionDeAvatar",
    component: () => import("@/views/Helexium/MiAvatar/CreacionDeAvatar"),
  },
  // market and canjes
  {
    path: "managerMarket",
    name: "managerMarket",
    meta: { name: "managerMarket " },
    component: ManagerMarket,
  },
  {
    path: "canjes",
    name: "canjes",
    meta: { name: "canjes " },
    component: Canjes,
  },
  {
    path: "market",
    name: "market",
    meta: { name: "market" },
    component: Market,
  },

  //Historial Clinico
  //Medico Ocupacional
  {
    path: "MedicoOcupacional/MedOcuLaboral",
    name: "MedOcuLaboral",
    meta: { name: "MedOcuLaboral" },
    component: MedOcuLaboral,
  },
  {
    path: "MedicoOcupacional/MedOcuEventos",
    name: "MedOcuEventos",
    meta: { name: "MedOcuEventos" },
    component: MedOcuEventos,
  },
  {
    path: "MedicoOcupacional/MedOcuFamiliar",
    name: "MedOcuFamiliar",
    meta: { name: "MedOcuFamiliar" },
    component: MedOcuFamiliar,
  },

  {
    path: "MedicoOcupacional/MedOcuPersonal",
    name: "MedOcuPersonal",
    meta: { name: "MedOcuPersonal" },
    component: MedOcuPersonal,
  },
  {
    path: "MedicoOcupacional/MedOcuHabitos",
    name: "MedOcuHabitos",
    meta: { name: "MedOcuHabitos" },
    component: MedOcuHabitos,
  },
  {
    path: "MedicoOcupacional/MedOcuFisico",
    name: "MedOcuFisico",
    meta: { name: "MedOcuFisico" },
    component: MedOcuFisico,
  },
  {
    path: "MedicoOcupacional/MedOcuOtros",
    name: "MedOcuOtros",
    meta: { name: "MedOcuOtros" },
    component: MedOcuOtros,
  },

  // registro servicios
  {
    path: "registroServicios",
    name: "registroServicios",
    meta: { name: "registroServicios" },
    component: RegistroServicios,
  },
  // GPS
  {
    path: "gps",
    name: "gps",
    meta: { name: "gps" },
    component: RegistroImeiGPS,
  },
  {
    path: "gps_agregar",
    name: "gps_agregar",
    meta: { name: "gps_agregar" },
    component: gps_agregar,
  },
  // map
  {
    path: "map",
    name: "map",
    meta: { name: "map" },
    component: Map,
  },
  {
    path: "mapGps",
    name: "mapgps",
    meta: { name: "mapgps" },
    component: MapGps,
  },
  {
    path: "GestionAccesos/:PersonaId",
    name: "GestionAccesos",
    meta: { name: "GestionAccesos" },
    component: GestionAccesos,
  },

  {
    path: "chat",
    name: "app.chat",
    meta: { name: "Chat" },
    component: Chat,
  },
  // plan de mejoramiento👇
  {
    path: "PlanMejoramiento",
    name: "PlanMejoramiento",
    meta: { name: "PlanMejoramiento" },
    component: PlanMejoramiento,
  },
  {
    path: "PlanMejoramiento_agregar",
    name: "PlanMejoramiento_agregar",
    meta: { name: "PlanMejoramiento_agregar" },
    component: PlanMejoramiento_agregar,
  },
  // {
  //   path: "PlanMejoramiento_finalizados",
  //   name: "PlanMejoramiento_finalizados",
  //   meta: { name: "PlanMejoramiento_finalizados" },
  //   component: PlanMejoramiento_finalizados,
  // },
  // plan de mejoramiento☝︝
  // mis actividades👇
  {
    path: "perfil/MisActividades",
    name: "MisActividades",
    meta: { name: "MisActividades" },
    component: MisActividades,
  },
  // mis actividades☝︝
  // onboarding👇
  {
    path: "Onboarding",
    name: "Onboarding",
    meta: { name: "Onboarding" },
    component: Onboarding,
  },
  {
    path: "Onboarding_admin",
    name: "Onboarding_admin",
    meta: { name: "Onboarding_admin" },
    component: Onboarding_admin,
  },
  {
    path: "Onboarding_agregar",
    name: "Onboarding_agregar",
    meta: { name: "Onboarding_agregar" },
    component: Onboarding_agregar,
  },
  // onboarding☝︝

  // capacitaciones👇
  {
    path: "Capacitaciones/RegistroCapacitaciones",
    name: "RegistroCapacitaciones",
    meta: { name: "RegistroCapacitaciones" },
    component: RegistroCapacitaciones,
  },
  {
    path: "Capacitaciones/RegistroCapacitaciones_agregar",
    name: "RegistroCapacitaciones_agregar",
    meta: { name: "RegistroCapacitaciones_agregar" },
    component: RegistroCapacitaciones_agregar,
  },
  {
    path: "Capacitaciones/MisCapacitaciones",
    name: "MisCapacitaciones",
    meta: { name: "MisCapacitaciones" },
    component: MisCapacitaciones,
  },
  // capacitaciones☝︝
  {
    path: "ReadXlsFile",
    name: "ReadXlsFile",
    meta: { name: "ReadXlsFile" },
    component: ReadXlsFile,
  },
  {
    path: "persona",
    name: "persona",
    meta: { name: "persona" },
    component: Persona,
  },
  {
    path: "empresa",
    name: "empresa",
    meta: { name: "empresa" },
    component: Empresa,
  },
  {
    path: "SGSST",
    name: "SGSST",
    meta: { name: "Data Table " },
    component: SGSST,
  },
  {
    path: "SGSST/Profesiograma",
    name: "SGSST/Profesiograma",
    meta: { name: "Data Table " },
    component: SGSSTProfesiograma,
  },
  {
    path: "SGSST/ListarProfesiograma",
    name: "SGSST/ListarProfesiograma",
    meta: { name: "Data Table " },
    component: SGSSTListarProfesiograma,
  },
  {
    path: "SGSST/InspeccionElementos",
    name: "SGSST/InspeccionElementos",
    meta: { name: "Data Table " },
    component: SGSSTInspeccionElementos,
  },
  {
    path: "SGSST/ListarInspeccionElementos",
    name: "SGSST/ListarInspeccionElementos",
    meta: { name: "Data Table " },
    component: SGSSTListarInspeccionElementos,
  },

  {
    path: "PESV",
    name: "PESV",
    meta: { name: "Data Table " },
    component: PESV,
  },
  {
    path: "Sucursales",
    name: "Sucursales",
    meta: { name: "Data Table " },
    component: Sucursales,
  },
  {
    path: "Departamentos",
    name: "Departamentos",
    meta: { name: "Data Table " },
    component: Departamentos,
  },
  {
    path: "Areas",
    name: "Areas",
    meta: { name: "Data Table " },
    component: Areas,
  },
  {
    path: "Competencias",
    name: "Competencias",
    meta: { name: "Data Table " },
    component: Competencias,
  },
  {
    path: "Medallas",
    name: "Medallas",
    meta: { name: "Data Table " },
    component: Medallas,
  },
  {
    path: "PESV/HojaDeVidaVehiculo",
    name: "HojaDeVidaVehiculo",
    meta: { name: "Data Table " },
    component: HojaDeVidaVehiculo,
  },
  {
    path: "PESV/RegistroDeVehiculos",
    name: "RegistroDeVehiculos",
    meta: { name: "Data Table " },
    component: RegistroDeVehiculos,
  },
  {
    path: "PESV/Indicadores",
    name: "IndicadoresPESV",
    meta: { name: "Data Table " },
    component: IndicadoresPESV,
  },
  {
    path: "PeligrosYRiesgos",
    name: "PeligroYRiesgos",
    meta: { name: "Data Table " },
    component: PeligrosYRiesgos,
  },
  {
    path: "PESV/FichaIndicadores",
    name: "PESVFichaIndicadores",
    meta: { name: "Data Table " },
    component: PESVFichaIndicadores,
  },
  {
    path: "InspeccionVehiculo",
    name: "InspeccionVehiculo",
    meta: { name: "Data Table " },
    component: InspeccionVehiculo,
  },

  {
    path: "Empresas_agregar",
    name: "Empresas_agregar",
    meta: { name: "Data Table " },
    component: Empresas_agregar,
  },
  {
    path: "Empresas_editar/:id",
    name: "Empresas_editar",
    meta: { name: "Data Table " },
    component: Empresas_editar,
  },
  {
    path: "Persona_agregar",
    name: "Persona_agregar",
    meta: { name: "Data Table " },
    component: Persona_agregar,
  },
  {
    path: "Persona_historia_laboral/:id",
    name: "Persona_historial_laboral",
    meta: { name: "Data Table " },
    component: Persona_historial_laboral,
  },
  {
    path: "Persona_editar/:id",
    name: "Persona_editar",
    meta: { name: "Data Table " },
    component: Persona_editar,
  },
  {
    path: "Sucursales_agregar",
    name: "Sucursales_agregar",
    meta: { name: "Data Table " },
    component: Sucursales_agregar,
  },
  {
    path: "Sucursales_editar/:id",
    name: "Sucursales_editar",
    meta: { name: "Data Table " },
    component: Sucursales_editar,
  },
  {
    path: "Departamentos_agregar",
    name: "Departamentos_agregar",
    meta: { name: "Data Table " },
    component: Departamentos_agregar,
  },
  {
    path: "Departamentos_editar/:id",
    name: "Departamentos_editar",
    meta: { name: "Data Table " },
    component: Departamentos_editar,
  },
  {
    path: "Areas_agregar",
    name: "Areas_agregar",
    meta: { name: "Data Table " },
    component: Areas_agregar,
  },
  {
    path: "Areas_editar/:id",
    name: "Areas_editar",
    meta: { name: "Data Table " },
    component: Areas_editar,
  },
  {
    path: "Competencias_agregar",
    name: "Competencias_agregar",
    meta: { name: "Data Table " },
    component: Competencias_agregar,
  },
  {
    path: "Competencias_editar/:id",
    name: "Competencias_editar",
    meta: { name: "Data Table " },
    component: Competencias_editar,
  },
  {
    path: "RegistroDeIncapacidades",
    name: "RegistroDeIncapacidades",
    meta: { name: "Data Table " },
    component: RegistroDeIncapacidades,
  },
  {
    path: "RegistroDeAccidentes",
    name: "RegistroDeAccidentes",
    meta: { name: "Data Table " },
    component: RegistroDeAccidentes,
  },
  {
    path: "finalizadas",
    name: "finalizadas",
    meta: { name: "Data Table " },
    component: Finalizadas,
  },
  {
    path: "caracterizacionEnfermedadLaboral",
    name: "CaracterizacionEnfermedadLaboral",
    meta: { name: "Data Table " },
    component: CaracterizacionEnfermedadLaboral,
  },

  {
    path: "hoja_vida",
    name: "hoja_vida",
    meta: { name: "Data Table " },
    component: hoja_vida,
  },
  {
    path: "hoja_vida/:id",
    name: "hoja_vida_detail",
    meta: { name: "Data Table " },
    component: hoja_vida,
  },
  {
    path: "perfil/cargo",
    name: "PerfilCargo",
    meta: { name: "Data Table " },
    component: PerfilCargo,
  },
  {
    path: "perfil/medallas",
    name: "PerfilMedallas",
    meta: { name: "Data Table " },
    component: PerfilMedallas,
  },
  {
    path: "perfil/comentarios",
    name: "PerfilComentarios",
    meta: { name: "Data Table " },
    component: PerfilComentarios,
  },
  {
    path: "Ciclo_evaluaciones_agregar",
    name: "Ciclo_evaluaciones_agregar",
    meta: { name: "Data Table " },
    component: Ciclo_evaluaciones_agregar,
  },
  {
    path: "SGSST/FichaIndicadores",
    name: "SGSSTFichaIndicadores",
    meta: { name: "Data Table " },
    component: SGSSTFichaIndicadores,
  },
  {
    path: "Pendientes",
    name: "Pendientes",
    meta: { name: "Data Table " },
    component: Pendientes,
  },

  {
    path: "Medallas_agregar",
    name: "Medallas_agregar",
    meta: { name: "Data Table " },
    component: Medallas_agregar,
  },
  {
    path: "Medallas_editar/:id",
    name: "Medallas_editar",
    meta: { name: "Data Table " },
    component: Medallas_editar,
  },

  // reportes

  {
    path: "Reportes",
    name: "Reportes",
    meta: { name: "Data Table " },
    component: Reportes,
  },
  {
    path: "Reportes_agregar",
    name: "Reportes_agregar",
    meta: { name: "Data Table " },
    component: Reportes_agregar,
  },
  {
    path: "Reportes_editar/:id",
    name: "Reportes_editar",
    meta: { name: "Data Table " },
    component: Reportes_editar,
  },

  {
    path: "Preguntas",
    name: "Preguntas",
    meta: { name: "Preguntas" },
    component: Preguntas,
  },
  {
    path: "Preguntas_agregar/:id",
    name: "Preguntas_agregar",
    meta: { name: "Preguntas_agregar" },
    component: Preguntas_agregar,
  },
  {
    path: "Preguntas_lista/:id",
    name: "Preguntas_lista",
    meta: { name: "Preguntas_lista" },
    component: Preguntas_lista,
  },
  {
    path: "Cargos",
    name: "Cargos",
    meta: { name: "Cargos" },
    component: Cargos,
  },
  {
    path: "Cargos_agregar",
    name: "Cargos_agregar",
    meta: { name: "Cargos_agregar" },
    component: Cargos_agregar,
  },
  {
    path: "CrearCargoV2",
    name: "CrearCargoV2",
    meta: { name: "CrearCargoV2" },
    component: () => import("@/views/Helexium/Cargos/CrearCargoV2"),
  },

  {
    path: "Cargos_editar/:id",
    name: "Cargos_editar",
    meta: { name: "Cargos_editar" },
    component: Cargos_editar,
  },

  {
    path: "Cargos_perfil/:id",
    name: "Cargos_perfil",
    meta: { name: "Cargos_perfil" },
    component: Cargos_perfil,
  },

  {
    path: "CargoPerfilV2/:id",
    name: "CargoPerfilV2",
    meta: { name: "CargoPerfilV2" },
    component: () => import("@/views/Helexium/Cargos/CargoPerfilV2"),
  },

  //academia y cursos
  {
    path: "Ayuda/Academia/AcademiaVideos/:id",
    name: "AcademiaVideos",
    meta: { name: "Data Table " },
    component: AcademiaVideos,
  },
  {
    path: "Ayuda/Academia",
    name: "Cursos",
    meta: { name: "Cursos" },
    component: Cursos,
  },
  {
    path: "Ayuda/Academia/InfoCurso/:id",
    name: "InfoCurso",
    meta: { name: "InfoCurso" },
    component: InfoCurso,
  },
  {
    path: "Ayuda/Soporte",
    name: "SoporteVideos",
    meta: { name: "Data Table " },
    component: SoporteVideos,
  },
  {
    path: "Ayuda/videoManager",
    name: "videoManager",
    meta: { name: "videoManager" },
    component: VideoManager,
  },
  {
    path: "Ciclo_evaluaciones",
    name: "Ciclo_evaluaciones",
    meta: { name: "Data Table " },
    component: Ciclo_evaluaciones,
  },
  {
    path: "Ciclo_evaluaciones_editar",
    name: "Ciclo_evaluaciones_editar",
    meta: { name: "Data Table " },
    component: Ciclo_evaluaciones_editar,
  },
  {
    path: "perfil/enviar_medallas",
    name: "enviar_medallas",
    meta: { name: "Data Table " },
    component: Enviar_medallas,
  },
  {
    path: "evaluacion/:id/:ciclo",
    name: "evaluacion",
    meta: { name: "Data Table " },
    component: evaluacion,
  },

  {
    path: "Tipo_Identidad",
    name: "Tipo_Identidad",
    meta: { name: "Data Table " },
    component: Tipo_Identidad,
  },
  {
    path: "Tipo_Competencias",
    name: "Tipo_Competencias",
    meta: { name: "Data Table " },
    component: Tipo_Competencias,
  },
  {
    path: "Tipo_Niveles_cargo",
    name: "Tipo_Niveles_cargo",
    meta: { name: "Data Table " },
    component: Tipo_Niveles_cargo,
  },
  {
    path: "dataLake",
    name: "dataLake",
    meta: { name: "dataLake " },
    component: DataLake,
  },

  {
    path: "Tickets",
    name: "Tickets",
    meta: { name: "Tickets " },
    component: Tickets,
  },

  {
    path: "AgregarTickets",
    name: "AgregarTickets",
    meta: { name: "AgregarTickets " },
    component: AgregarTickets,
  },

  {
    path: "ChatTicket/:userid/:id",
    name: "ChatTicket",
    meta: { name: "ChatTicket " },
    component: ChatTicket,
  },

  {
    path: "AdministradorDeVacantes",
    name: "AdministradorDeVacantes",
    meta: { name: "AdministradorDeVacantes " },
    component: AdministradorDeVacantes,
  },
  {
    path: "NuevaVacante",
    name: "NuevaVacante",
    meta: { name: "NuevaVacante" },
    component: NuevaVacante,
  },
  {
    path: "EditarVacante/:id",
    name: "EditarVacante",
    meta: { name: "EditarVacante" },
    component: EditarVacante,
  },

  {
    path: "ListaDePostulantes/:vacanteId",
    name: "ListaDePostulantes",
    meta: { name: "ListaDePostulantes" },
    component: ListaDePostulantes,
  },
  {
    path: "ProcesoDePreseleccion/:vacanteId",
    name: "ProcesoDePreseleccion",
    meta: { name: "ProcesoDePreseleccion" },
    component: ProcesoDePreseleccion,
  },
  {
    path: "NuevaPostulacion/:vacanteId",
    name: "NuevaPostulacion",
    meta: { name: "NuevaPostulacion" },
    component: NuevaPostulacion,
  },
  {
    path: "RegistroDePostulacion/:id",
    name: "RegistroDePostulacion",
    meta: { name: "RegistroDePostulacion" },
    component: RegistroDePostulacion,
  },
  {
    path: "ProcesoDeSeleccion/:vacanteId",
    name: "ProcesoDeSeleccion",
    meta: { name: "ProcesoDeSeleccion" },
    component: ProcesoDeSeleccion,
  },
  {
    path: "EvaluarDocumentosDeSeleccion/:vacanteId/:postulacionId",
    name: "EvaluarDocumentosDeSeleccion",
    meta: { name: "EvaluarDocumentosDeSeleccion" },
    component: EvaluarDocumentosDeSeleccion,
  },
  {
    path: "VinculacionDePostulantes/:vacanteId",
    name: "VinculacionDePostulantes",
    meta: { name: "VinculacionDePostulantes" },
    component: VinculacionDePostulantes,
  },


  {
    path: "Honorarios",
    name: "Honorarios",
    meta: { name: "Honorarios" },
    component: Honorarios,
  },




  {
    path: "numeraciones",
    name: "numeraciones",
    meta: { name: "numeraciones" },
    component: Numeraciones,
  },
  {
    path: "numeracion_agregar",
    name: "numeracion_agregar",
    meta: { name: "numeracion_agregar" },
    component: Numeracion_agregar,
  },
  {
    path: "numeracion_editar/:id",
    name: "numeracion_editar",
    meta: { name: "numeracion_editar" },
    component: Numeracion_editar,
  },
  {
    path: "devengados",
    name: "devengados",
    meta: { name: "devengados" },
    component: Devengados,
  },
  {
    path: "deducciones",
    name: "deducciones",
    meta: { name: "deducciones" },
    component: Deducciones,
  },
  {
    path: "nominas",
    name: "nominas",
    meta: { name: "nominas" },
    component: Nominas,
  },
  {
    path: "nomina_editar/:id",
    name: "nomina_editar",
    meta: { name: "nomina_editar" },
    component: Nomina_Editar,
  },
  {
    path: "nomina_editar/:liquidacionId/ajute_persona/:liquidacionPersonaId",
    name: "NominaAjustePersona",
    meta: { name: "NominaAjustePersona" },
    component: () =>
      import("@/views/Helexium/Nomina/NominaAjustePersona/NominaAjustePersona"),
    props: (route) => ({
      liquidacionId: String(route.params.liquidacionId),
      liquidacionPersonaId: String(route.params.liquidacionPersonaId),
    }),
  },
  {
    path: "Preparar/:liquidacionId",
    name: "Preparar",
    meta: { name: "Preparar" },
    component: () => import("@/views/Helexium/Nomina/Preparar/NominaPreaparar"),
    props: (route) => ({ liquidacionId: String(route.params.liquidacionId) }),
  },
  {
    path: "RegistroNovedades/:Id",
    name: "RegistroNovedades",
    meta: { name: "RegistroNovedades" },
    component: RegistroNovedades,
  },
  {
    path: "liquidacion",
    name: "liquidacion",
    meta: { name: "liquidacion" },
    component: Liquidacion,
  },
  {
    path: "liquidacion_agregar",
    name: "liquidacion_agregar",
    meta: { name: "liquidacion_agregar" },
    component: Liquidacion_Agregar,
  },
  {
    path: "liquidacion_editar/:id",
    name: "liquidacion_editar",
    meta: { name: "liquidacion_editar" },
    component: Liquidacion_Editar,
  },
  {
    path: "liquidacion_editarv2/:id",
    name: "liquidacion_editarv2",
    meta: { name: "liquidacion_editarv2" },
    component: LiquidacionEditarPrimaServicios,
  },
  {
    path: "liquidacioneditardetalle/:id/:user",
    name: "liquidacioneditardetalle",
    meta: { name: "liquidacioneditardetalle" },
    component: liquidacioneditardetalle,
  },
  {
    path: "liquidacioneditardetallev2/:id/:user",
    name: "liquidacioneditardetallev2",
    meta: { name: "liquidacioneditardetallev2" },
    component: liquidacioneditardetallev2,
  },
  {
    path: "AutorizacionFunciones",
    name: "AutorizacionFunciones",
    meta: { name: "AutorizacionFunciones" },
    component: AutorizacionFunciones,
  },
  {
    path: "TerminosYCondiciones",
    name: "TerminosYCondiciones",
    meta: { name: "TerminosYCondiciones" },
    component: TerminosYCondiciones,
  },
  // {
  //   path: ':area/FichaIndicadores',
  //   name: 'FichaIndicadores',
  //   meta: {  name: 'Data Table ' },
  //   component: FichaIndicadores
  // },
  // {
  //   path: 'caracterizacionEnfermedadLaboral',
  //   name: 'helexium.CaracterizacionEnfermedadLaboral',
  //   meta: {  name: 'Data Table ' },
  //   component: CaracterizacionEnfermedadLaboral
  // },
  {
    path: "nomina",
    name: "Nomina",
    component: () =>
      import("@/views/Helexium/Nomina/ServicionNominaAportesEnLinea/Nomina"),
    redirect: { name: "NominaDashboard" },
    children: [
      {
        path: "dashboard",
        name: "NominaDashboard",
        component: () =>
          import(
            "@/views/Helexium/Nomina/ServicionNominaAportesEnLinea/Nomina/NominaDashboard"
          ),
      },
      {
        path: "proceso",
        name: "NominaProceso",
        component: () =>
          import(
            "@/views/Helexium/Nomina/ServicionNominaAportesEnLinea/Nomina/NominaProceso"
          ),
        redirect: { name: "Periodo" },
        children: [
          {
            path: "personas_novedades",
            name: "PersonasNovedades",
            component: () => import("@/views/Helexium/Persona/Persona"),
          },
          {
            path: "persona_novedades/:Id",
            name: "PersonaNovedades",
            component: () =>
              import(
                "@/views/Helexium/Nomina/RegistroNovedades/RegistroNovedades"
              ),
          },
          {
            path: "periodo",
            name: "Periodo",
            component: () =>
              import("@/views/Helexium/Nomina/Liquidacion/Liquidacion_Agregar"),
          },
          {
            path: "preparar/:liquidacionId",
            name: "NominaPreparar",
            component: () =>
              import("@/views/Helexium/Nomina/Preparar/NominaPreaparar"),
            props: (route) => ({
              liquidacionId: String(route.params.liquidacionId),
            }),
          },
          {
            path: "transmitir/:liquidacionId",
            name: "NominaTransmitir",
            component: () =>
              import(
                "@/views/Helexium/Nomina/ServicionNominaAportesEnLinea/Nomina/Proceso/NominaTransmitir"
              ),
            props: (route) => ({
              liquidacionId: String(route.params.liquidacionId),
            }),
          },
        ],
      },
    ],
  },
  {
    path: "LegoSistemaDeGestion",
    name: "Lego",
    component: () => import("@/views/Helexium/Lego/LegoLayout"),
    redirect: { name: "TusTableros" },
    children: [
      {
        path: "TusTableros",
        name: "TusTableros",
        component: () => import("@/views/Helexium/Lego/LegoViewTusTableros"),
        children: [
          {
            path: "CrearTablero",
            name: "CrearTablero",
            component: () =>
              import("@/views/Helexium/Lego/LegoViewInModalCrearTablero"),
          },
        ],
      },
      {
        path: "LegoAdministradorHelexium",
        name: "LegoAdministradorHelexium",
        component: () =>
          import("@/views/Helexium/Lego/LegoViewAdministradorHelexium"),
      },
      {
        path: "Tablero/:boardId",
        name: "Tablero",
        component: () => import("@/views/Helexium/Lego/LegoViewTablero"),
        props: (route) => ({ boardId: String(route.params.boardId) }),
        children: [
          {
            path: "Colaboradores",
            name: "TableroColaboradores",
            component: () =>
              import("@/views/Helexium/Lego/LegoViewInModalColaboradores"),
            props: (route) => ({ ...route.params }),
          },
          {
            path: "Tarjeta/:cardId",
            name: "LegoTarjeta",
            component: () =>
              import("@/views/Helexium/Lego/LegoViewInModalTarjeta"),
            props: (route) => ({ ...route.params, card: route.params.cardId }),
          },
          {
            path: "NuevaPlantilla",
            name: "ToDoTemplate",
            component: () =>
              import("@/views/Helexium/Lego/LegoViewInModalToDoTemplate"),
            props: (route) => ({ ...route.params }),
          },
          {
            path: ":process/:cardId",
            name: "LegoCardProcess",
            component: () =>
              import("@/views/Helexium/Lego/LegoViewInModalProcess"),
            props: (route) => ({ ...route.params, card: route.params.cardId }),
          },
        ],
      },
    ],
  },
  {
    path: "assistant",
    name: "assistantView",
    component: () => import("@/views/Helexium/Assistant/AssistantView"),
    redirect: { name: "ChatResultadosEvaluacion" },
    children: [
      {
        path: "ResultadosEvaluacion",
        name: "ChatResultadosEvaluacion",
        component: () =>
          import("@/views/Helexium/Assistant/ChatResultadosEvaluacion"),
      },
    ],
  },

  {
    // Modulo para verificar conexion a la api de php
    path: "ConexionDian",
    name: "ConexionDian",
    component: () => import("@/views/Helexium/ConexionDian/ConexionDian"),
  },

  {
    path: "LiquidadorNomina/:liquidacionId",
    name: "LiquidadorNomina",
    component: () =>
      import("@/views/Helexium/Nomina/LiquidadorNomina/LiquidadorNomina"),
    props: (route) => ({ liquidacionId: String(route.params.liquidacionId) }),
  },
];

const routes = [
  {
    path: "/dashboard",
    name: "dashboard_nuevo",
    meta: { name: "dashboard_nuevo" },
    component: dashboard_nuevo,
  },
  {
    path: "/",
    name: "auth.login",
    meta: { name: "SignIn" },
    component: SignIn,
  },

  {
    path: "/helex",
    name: "helex",
    component: () => import("@/layouts/backend/Layout-9"),
    children: helexiumRoutes(),
  },

  {
    path: "*",
    name: "_404",
    component: NotFound,
  },

  ...publicViews,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
});

router.beforeEach((to, _, next) => {
  if (to.name != "auth.login") {
    userLoggedIn();
  }
  next();
});

// import store from "@/store";
// const groups = require("@/components/partials/backend/HeaderStyle/groups.json").data;
// let subGrupos = (groups.map((group) => group.items)).flat()
// let ordenAcceso = [
//   ...subGrupos, ...groups
// ]

// router.beforeEach((to, _, next) => {
//   try {
//     let rutaActual = ordenAcceso.find((item) => {
//       try {
//         return item.path == to.path
//       } catch (error) {
//         return false
//       }
//     })
//     if (rutaActual == undefined) next()
//     let mapa = store.state.userLoggedIn.mapa.split(",")
//     console.log("to", to, mapa, typeof mapa, mapa[rutaActual.ordenAcceso])
//     let condicion = parseInt(mapa[rutaActual.ordenAcceso])
//     if (condicion) {
//       next()
//       console.log("puede entrar")
//     } else {
//       next({ name: "dashboard_nuevo" })
//       console.log("NO puede entrar")
//     }
//   } catch (error) {
//     console.error("beforeEach error", error)
//   }

// })

export default router;
