<template>
  <div class="">
    <!-- <vs-button warn icon p-3   size="xl" @click="dark = false" v-model="dark" :style="dark == false ? `width: 40px; height: 40px;  display:none; ;` : ``">
      <i class="fa-solid fa-sun" style="font-size: 28px !important"></i>
    </vs-button> -->
    <!-- <vs-button class="ml-2" dark   icon p-3 size="xl" @click="dark = true" v-model="dark" :style="dark == true ? `width: 40px; height: 40px; display:none;` : ``">
      <i class="fa-solid fa-moon px-1" style="font-size: 28px !important"></i>
    </vs-button> -->
    <vs-button
      class="ml-2 logo-l-d-mode p-2"
      :warn="dark == true || dark == 'true' ? true : false"
      :dark="dark == false || dark == 'false' ? true : false"
       
      circle
      @click="dark = !dark"
      v-model="dark"
    >
      <i :class="'fa-solid ' + (dark == true || dark == 'true' ? ' fa-sun ' : ' fa-moon ')" style="font-size: 28px !important; padding: 50px !important"></i>
    </vs-button>
    <!-- <vs-button size="large" icon  success @click="dark=false" v-model="dark" :style="dark==false ? `display:none;` : ``">
        <i class="fa-solid fa-sun "></i>
    </vs-button>
    <vs-button size="large" icon  danger @click="dark=true" v-model="dark" :style="dark==true ? `display:none;` : ``">
        <i class="fa-solid fa-moon "></i>
    </vs-button> -->
    <!-- <div class="custom-control custom-switch custom-switch-icon custom-control-inline">
        <div class="custom-switch-inner">
            <p class="mb-0"> </p>
            <input type="checkbox" v-model="dark" class="custom-control-input" id="dark-mode" data-active="true">
            <label class="custom-control-label" for="dark-mode" data-mode="toggle">
                <span class=" switch-icon-left"><i class="a-left fa-solid fa-sun"></i></span>
                <span class=" switch-icon-right"><i class="a-right fa-solid fa-moon"></i></span>
            </label>
        </div>
    </div>  -->
  </div>
  <!-- <div class="change-mode mb-3">
    <div class="custom-control custom-switch custom-switch-icon custom-control-inline">
        <div class="custom-switch-inner">
            <p class="mb-0"> </p>
            <input type="checkbox" v-model="dark" class="custom-control-input" id="dark-mode" data-active="true">
            <label class="custom-control-label" for="dark-mode" data-mode="toggle">
                <span class=" switch-icon-left"><i class="a-left fa-solid fa-sun"></i></span>
                <span class=" switch-icon-right"><i class="a-right fa-solid fa-moon"></i></span>
            </label>
        </div>
    </div>
</div> -->
</template>
<script>
export default {
  name: "ModeSwitch",
  data() {
    return {
      dark: false,
    };
  },
  watch: {
    dark: function () {
      this.changeMode();
    },
  },
  mounted() {
    let darkmode = localStorage.getItem("modoseleccionado");
    if (darkmode == undefined || darkmode == null) darkmode = true;
    localStorage.setItem("modoseleccionado", darkmode);

    this.dark = darkmode;
    //localStorage.getItem('modoseleccionado') == true || localStorage.getItem('modoseleccionado') == 'true'  ? true : false
  },
  methods: {
    changeMode() {
      const body = document.querySelector("body");

      if (this.dark) {
        body.classList.add("dark");
        localStorage.setItem("modoseleccionado", true);
      } else {
        body.classList.remove("dark");
        localStorage.setItem("modoseleccionado", false);
      }
    },
  },
};
</script>
<style>
.logo-l-d-mode {
  border-radius: 50%;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 25px;
  cursor: pointer;
  transition: 0.3s;
}
.warn {
  /* background-color: yellow; */
}

:root {
  --vs-warn: 254, 114, 28 !important;
}
.buttons {
  margin: 0 !important;
}

.manageDarkButton {
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  margin: 0 !important;
}
</style>
