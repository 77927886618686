const store = {
  namespaced: true,
  state: {
    codesTerminal: [],
    divipolaCodesDepartamentsAndMunicipalities: [],
    listTypesDocs: [],
    allDataTerminals: {},
    passengerOptions: [],
  },
  mutations: {
    setCodesTerminal(state, codesTerminal) {
      state.codesTerminal = codesTerminal;
    },
    setPassengerOptions(state, passengerOptions) {
      state.passengerOptions = passengerOptions;
    },
    setDivipolaCodesDepartamentsAndMunicipalities(state, divipolaCodes) {
      state.divipolaCodesDepartamentsAndMunicipalities = [...divipolaCodes];
    },

    setListTypesDocs(state, listTypesDocs) {
      state.listTypesDocs = listTypesDocs;
    },
    setAllDataTerminals(state, allDataTerminals) {
      state.allDataTerminals = allDataTerminals;
    },
  },
  actions: {
    async getAllDataTerminals({ rootGetters, commit }) {
      const empresaId = rootGetters.userLoggedIn.empresa.id;
      const response = await rootGetters.fetchGet({
        path: `Terminales/GetAllData/${empresaId}`,
      });
      if (response.ok) {
        const allData = await response.json();

        commit("setAllDataTerminals", allData);
      }
    },
    async getCodesTerminal({ rootGetters, commit }) {
      const response = await rootGetters.fetchGet({
        path: `Terminales/Codigos`,
      });
      if (response.ok) {
        const codes = await response.json();

        commit("setCodesTerminal", codes);
      }
    },
    async getListTypesDocs({ rootGetters, commit }) {
      const response = await rootGetters.fetchPost({
        path: `TipoDocumento/ListTipoDocumento`,
      });
      if (response.ok) {
        const listTypesDocs = await response.json();

        const mappedTypesDocs = listTypesDocs.map((typeDoc, _index) => {
          const id = typeDoc['id'] = _index
          return {
              id,
            ...typeDoc,
          };
        });

        commit("setListTypesDocs", mappedTypesDocs);
      }
    },

    getPassengerOptions({ commit }) {
      const passengerOptions = Array.from({ length: 100 }, (_, i) => ({
        id: i + 1,
        label: (i + 1).toString() + (i != 0 ? " Pasajeros" : " Pasajero"),
      }));

      commit("setPassengerOptions", passengerOptions);
    },

    async getDivipolaCodes({ commit }) {
      try {
        const response = await fetch(
          "https://www.datos.gov.co/api/id/gdxc-w37w.json?$query=select%20*%2C%20%3Aid%20limit%201126"
        );
        const municipalities = await response.json();

        // Filter out invalid records
        const validMunicipalities = municipalities.filter(
          (municipio) => municipio.cod_depto && !isNaN(municipio.cod_depto)
        );

        // Group municipalities by department
        const groupedByDepartment = Object.values(
          validMunicipalities.reduce((acc, municipality) => {
            const { cod_depto, dpto, nom_mpio, cod_mpio } = municipality;

            if (!acc[cod_depto]) {
              acc[cod_depto] = {
                cod_depto,
                dpto,
                municipalities: [],
              };
            }

            acc[cod_depto].municipalities.push({ cod_mpio, nom_mpio });

            return acc;
          }, {})
        );

        commit(
          "setDivipolaCodesDepartamentsAndMunicipalities",
          groupedByDepartment
        );
      } catch (error) {
        console.error("Error fetching Divipola codes:", error);
      }
    },
  },
};

export default store;
