const store = {
  namespaced: true,
  state: {
    // sisi pesv:  QA - entorno de pruebas
    HOST_CORE: "http://179.1.200.179:3333/api/v1/",
    TOKEN_SISI: "505f54e7-9515-43f7-9b29-bda9a1673fad",
    credential: {
      usuario: "900413751",
      contrasena: "Sup3r@2024",
    },
    sisiAuth: null,
    activePasoFase1: 1,
  },
  mutations: {
    setSisiAuth(state, data) {
      state.sisiAuth = data;
      localStorage.setItem("sisiAuth", JSON.stringify(state.sisiAuth));
    },
    setPasoFase1(state, data) {
      state.activePasoFase1 = data;
    },
  },
  actions: {
    // sisi pesv
    async useRequestSisi(
      context,
      { method, path, params, body, isFormData = false }
    ) {
      let data, ok;
      try {
        let token =
          JSON.parse(localStorage.getItem("sisiAuth") || "{}").token ?? "";

        if (!method) throw new Error("El método es requerido");
        if (!path) throw new Error("La ruta es requerida");

        // Construir la URL con parámetros de consulta
        const url = new URL(context.state.HOST_CORE + path);
        if (params) {
          Object.keys(params).forEach((key) =>
            url.searchParams.append(key, params[key])
          );
        }

        const options = {
          method,
          headers: {
            Authorization: "Bearer " + token,
          },
          body: isFormData ? body : JSON.stringify(body),
        };

        if (!isFormData) {
          options.headers["Content-Type"] = "application/json";
        }

        const res = await fetch(url.toString(), options);
        ok = res.ok;
        const req = await res.json();
        if (res.error) throw new Error(res.error.message);

        data = req;
      } catch (error) {
        console.error("error", error.message);
        return error;
      }

      return { ok, data };
    },
  },
};

export default store;
