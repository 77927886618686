import Swal from 'sweetalert2'
import { set } from 'vue'

function wrapperCatchError( func ) {
  try {
    func()
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message
    })
  }
}

const store = {
  namespaced: true,
  state: {
    boards: [],
    board: {},
    card: {},
    currencyRole: {},
    auditoriaOptions: []
  },
  mutations: {
    setCurrencyRole( state, role ) {
      state.currencyRole = role
    },
    setAuditoriaOptions( state, options ) {
      state.auditoriaOptions = options
    },
    setBoards( state, boards ) {
      state.boards = boards
    },
    pushBoard( state, board ) {
      state.boards.push( board )
    },
    setBoard( state, board ) {
      state.board = board
    },
    updateCollaboratorsInBoard( state, collaborators ) {
      set(state.board, 'collaborators', collaborators)
    },
    setColumns( state, columns ) {
      set(state.board, 'columns', columns)
    },
    setCards( _, { column, cards}) {
      set(column, 'cards', cards)
    },
    setCardsByColumnId( state, { columnId, cards }) {
      const column = state.board.columns.find( c => c.id == columnId )
      set(column, 'cards', cards)
    },
    setCard( state, card ) {
      state.card = card
      // console.log("card>>>>>>>>>>>", card);
      
      if(Object.keys(card).length && Object.keys(state.board).length && card.legoColumnId) {
        const column = state.board.columns.find( c => c.id == card.legoColumnId )
        const index = column.cards.findIndex( c => c.id == card.id )
        set( column.cards, index, card)
      } 

    },
    setItemInColumn( state, { columnId, obj } ) {
      const column = state.board.columns.find( c => c.id == columnId )
      for( let key of Object.keys(obj) ) {
        set(column, key, obj[key])
      }
    },
    pushColumn( state, column ) {
      const columns = state.board.columns
      columns.push( column )
      set(state.board, 'columns', columns)
    },
    pushCard( state, card ) {
      const column = state.board.columns.find( c => c.id == card.legoColumnId )
      let cards = column.cards
      cards.push( card )
      set(column, 'cards', cards)
    },
    pushSubCard( state, subCard ) {
      const card = state.card
      let dependents = [...card.dependents]
      dependents.push( subCard )
      set(card, 'dependents', dependents)
    },
    pushChecklist( state, checklist ) {
      const card = state.card
      let checklists = [...card.checklists]
      checklists.push( checklist )
      set(card, 'checklists', checklists)
    },
    pushItemInChecklist( state, item ) {
      const checklist = state.card.checklists.find( c => c.id == item.legoChecklistId )
      let items = [...checklist.items]
      items.push( item )
      set(checklist, 'items', items)
      
    },
    pushCommentInCard( state, comment ) {
      const card = state.card
      let comments = [...card.comments]
      comments.push( comment )
      set(card, 'comments', comments)
    },
    pushAttachedFileInCard( state, newFile ) {
      const card = state.card
      let attachedFiles = [...card.attachedFiles]
      attachedFiles.push( newFile )
      set( card, 'attachedFiles', attachedFiles)
    },
    updateItemInChecklist(state, item ) {
      const checklist = state.card.checklists.find( c => c.id == item.legoChecklistId )
      const items = checklist.items.map( i => i.id == item.id ? item : i )
      set(checklist, 'items', items)
    },
    renameChecklist(state, data ) {
      const index = state.card.checklists.findIndex( c => c.id == data.id )
      const newChecklist = Object.assign({}, state.card.checklists[index], { title: data.title })
      set(state.card.checklists, index, newChecklist)
    },
    setIsDoneCard(state, card ) {
      // solo si estamos en la ruta de card
      if( Object.keys(card).length ) 
      {
        set(state.card, 'isDone', card.isDone)
        set(state.card, 'doneBy', card.doneBy)
        set(state.card, 'doneAt', card.doneAt)
      }

      let allCards = state.board.columns.map( c => c.cards ).flat()
      allCards = [...allCards, ...allCards.map( c => c.dependents ).flat()]

      const cardToUpdate = allCards.find( c => c.id == card.id )
      set(cardToUpdate, 'isDone', card.isDone)

      // console.log("allCards>>>>>>>>>>>>>>>", allCards.length, allCards);
      // console.log(card);

    },
    deleteItemInChecklist(state, item ) {
      const checklist = state.card.checklists.find( c => c.id == item.legoChecklistId )
      const items = checklist.items.filter( i => i.id != item.id )
      set(checklist, 'items', items)
    },
    deleteChecklist(state, checklist ) {
      const card = state.card
      const checklists = card.checklists.filter( c => c.id != checklist.id )
      set(card, 'checklists', checklists)
    },
    moveCard(  ) {
      console.log("Todo piola");
      // draggable afecta una parte de la actualización y es necesario este complemento.
      // let auxCards

      // const fromColumn = state.board.columns.find( c => c.id == data.fromId )
      // auxCards = [...fromColumn.cards]
      // auxCards.splice(data.oldIndex, 1)
      // set(fromColumn, 'cards', auxCards)
      
      // const toColumn = state.board.columns.find( c => c.id == data.toId )
      // const cards = state.board.columns.map( c => c.cards ).flat()
      // const card = cards.find( c => c.id == data.cardId )
      // auxCards = [...toColumn.cards]
      // auxCards.splice(data.newIndex, 0, card)
      // set(toColumn, 'cards', auxCards) 

      
    },
    pushAssignmentInCard( state, assignment ) {
      // dar poderes al tablero
      let allCards = state.board.columns.map( c => c.cards ).flat()
      allCards = [...allCards, ...allCards.map( c => c.dependents ).flat()]
      const cardToUpdate = allCards.find( c => c.id == assignment.legoCardId )
      let assignments = [...cardToUpdate.assignments]
      assignments.push( assignment )
      set(cardToUpdate, 'assignments', assignments)

      if( Object.keys(state.card).length ) 
      {
        set(state.card, 'assignments', assignments)
      }
    },
    deleteAssignment( state, assignment ) {
      // dar poderes al tablero
      let allCards = state.board.columns.map( c => c.cards ).flat()
      allCards = [...allCards, ...allCards.map( c => c.dependents ).flat()]
      const cardToUpdate = allCards.find( c => c.id == assignment.legoCardId )
      let assignments = cardToUpdate.assignments.filter( a => a.id != assignment.id )
      set(cardToUpdate, 'assignments', assignments)

      
      if( Object.keys(state.card).length )
      {
        set(state.card, 'assignments', assignments)
      }
    },
    setBoardTitle( state, title ) {
      set(state.board, 'title', title)
    },
  },
  actions: {
    assertErrorRoleNotOwner({state}){
      const roleLevel = state.currencyRole.level

      if( !roleLevel ) {
        throw new Error('Danos 5 segundos, la conexión está lenta...')
      }

      if( roleLevel > 1 ) {
        throw new Error('Solo el propietario del tablero puede hacer esta acción.')
      }
    },

    assertErrorRoleAuditorOrOwner({state}) {
      const roleLevel = state.currencyRole.level

      if( !roleLevel ) {
        throw new Error('Danos 5 segundos, la conexión está lenta...')
      }

      if( roleLevel > 3 ) {
        throw new Error('Los invitados no pueden hacer modificaciones al tablero.')
      }

      if( roleLevel > 2 ) {
        throw new Error('Solo los auditores o el propietario del tablero pueden hacer esta acción.')
      }

    },

    assertErrorRoleNotCollaborator({state, rootGetters}) {
      const roleLevel = state.currencyRole.level
      const assignments = state.card.assignments.map( a => a.personaId )
      const userId = rootGetters.userLoggedIn.id

      if( !roleLevel ) {
        throw new Error('Danos 5 segundos, la conexión está lenta...')
      }

      if( roleLevel > 3 ) {
        throw new Error('Los invitados no pueden hacer modificaciones al tablero.')
      }

      if( roleLevel > 2 && !assignments.includes(userId) ) {
        throw new Error('No estás asignado a esta tarea. Puedes comunicarte con un auditor o el propietario del tablero.')
      }
    },

    assertErrorRoleNotAuditor({state}) {
      const roleLevel = state.currencyRole.level

      if( !roleLevel ) {
        throw new Error('Danos 5 segundos, la conexión está lenta...')
      }

      if( roleLevel > 3 ) {
        throw new Error('Solo los auditores pueden hacer esta acción.')
      }
    },

    assertErrorRoleNotCreator({state, rootGetters}, createdBy ) {
      const roleLevel = state.currencyRole.level
      const userId = rootGetters.userLoggedIn.id

      if( !roleLevel ) {
        throw new Error('Danos 5 segundos, la conexión está lenta...')
      }

      if( roleLevel > 3 ) {
        throw new Error('Los invitados no pueden hacer modificaciones al tablero.')
      }

      if( roleLevel > 1 && createdBy != userId ) {
        throw new Error('No eres el creador de esta tarea. puedes comunicarte con el creador o el propietario del tablero.')
      }


    },

    async getAndSetBoards({rootGetters, getters, commit}) {
      const userId = rootGetters.userLoggedIn.id
      const response = await rootGetters.fetchGet({ path: `lego/BoardsByUserId/${userId}` })
      if (response.ok) {
        let boards = await response.json()
        boards.forEach( board => {
          board.roleInBoard = getters.getRoleInBoard( userId, board.collaborators )
          return board
        });
        commit('setBoards', boards )
      }
    },
    async getAndSetBoard({rootGetters, getters, commit}, boardId) {
      try {
        const response = await rootGetters.fetchGet({ path: `lego/BoardById/${boardId}` })
        if (response.ok) {
          let board = await response.json()
          board.columns = board.columns.sort( (a,b) => a.order - b.order )
          board.columns.forEach( column => {
            column.cards = column.cards.sort( (a,b) => a.order - b.order )
          })

          board.roleInBoard = getters.getRoleInBoard( rootGetters.userLoggedIn.id, board.collaborators )
          commit('setBoard', board )
        } else {
          throw new Error('No se pudo recuperar el tablero')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },
    async getAndSetBoardDeep({rootGetters, getters, commit}, boardId) {
      try {
        const response = await rootGetters.fetchGet({ path: `lego/BoardDeepById/${boardId}` })
        if (response.ok) {
          let board = await response.json()
          board.columns = board.columns.sort( (a,b) => a.order - b.order )
          board.columns.forEach( column => {
            column.cards = column.cards.sort( (a,b) => a.order - b.order )
          })

          board.roleInBoard = getters.getRoleInBoard( rootGetters.userLoggedIn.id, board.collaborators )

          commit('setCurrencyRole', board.roleInBoard)
          commit('setBoard', board )
        } else {
          throw new Error('No se pudo recuperar el tablero')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },
    async getCommentsInCard({rootGetters}, cardId) {
      const response = await rootGetters.fetchGet({ path: `lego/CommentsByCardId/${cardId}` })
      if (response.ok) {
        return await response.json()
      } else {
        return []
      }
    },

    async getChecklistsInCard({rootGetters}, cardId) {
      const response = await rootGetters.fetchGet({ path: `lego/ChecklistsByCardId/${cardId}` })
      if (response.ok) {
        return await response.json()
      } else {
        return []
      }
    },

    async getAndSetCard({rootGetters, commit, dispatch}, cardId) {

      try {
        const response = await rootGetters.fetchGet({ path: `lego/CardById/${cardId}` })
        if (response.ok) {
          let card = await response.json()
          card.comments = await dispatch('getCommentsInCard', cardId)
          card.checklists = await dispatch('getChecklistsInCard', cardId)
          commit('setCard', card )
        } else {
          throw new Error('No se pudo recuperar la tarjeta')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    cleanBoard({commit}) {
      commit('setBoard', {})
    },
    async deleteBoard({rootGetters, dispatch}, board) {
      try {
        dispatch('assertErrorRoleNotOwner')

        await Swal.fire({
          title: `¿Desea eliminar el tablero?`,
          text: `El tablero ${board.name} será eliminado y no podrá recuperarse.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33'
        })
        .then( async (result) => {
          if (result.isConfirmed) {
        
            const response = await rootGetters.fetchDelete({ path: `Lego/DeleteBoard/${board.id}`})
            if(response.ok) {
              await Swal.fire(
                'Eliminado!',
                'El tablero ha sido eliminado.',
                'success'
              )
            }
        
          }
        })
    
      } catch( error ) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },
    async crearTablero({rootGetters, dispatch}, nuevoTablero) {
      try {
        const response = await rootGetters.fetchPost({ path: 'lego/CreateBoard', data: nuevoTablero })
        if (response.ok) {
          dispatch('getAndSetBoards')
        } else {
          throw new Error('No se pudo crear el tablero')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    setBoardTitle({rootGetters, commit, state, dispatch}, card) {
      wrapperCatchError( () => {
        
        if( card.title == state.board.title ) return;
        dispatch('assertErrorRoleAuditorOrOwner')

        commit('setBoardTitle', card.title)
        const prevTitle = state.board.title
  
        console.log(prevTitle, card.id);
  
        rootGetters.fetchPut({
          path: `Lego/UpdateBoardTitle/${card.id}`,
          data: card
        }).then( response => {
          if( response.ok ) {
            Swal.fire({
              title: 'Actualizado',
              text: 'Se actualizó el título',
              icon: 'success',
              confirmButtonText: 'OK'
            })
          } else {
            throw new Error( 'No se pudo actualizar el título' );
          }
        })
      })
    },

    async updateCollaboratorsInBoard({rootGetters, commit}, { boardId, collaborators }) {
      const response = await rootGetters.fetchPut({
        path: `Lego/UpdateCollaborators/${boardId}`,
        data: collaborators
      })
      
      if( response.ok ) {
        const collaborators = await response.json()
        commit('updateCollaboratorsInBoard', collaborators)

        await Swal.fire({
          title: 'Actualizado',
          text: 'Se actualizaron los colaboradores',
          icon: 'success',
          confirmButtonText: 'OK'
        })


      } else {
        await Swal.fire({
          title: 'Error',
          text: 'No se actualizaron los colaboradores',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
    },
    async renameChecklist({rootGetters, commit, state, dispatch}, checklist ) {
      try {
        dispatch('assertErrorRoleNotCreator', state.card.createdBy )
        
        // la mejor forma de hacerlo: acción prospectiva
        commit('renameChecklist', {id: checklist.id, title: checklist.newTitle})
        
        const response = await rootGetters.fetchPut({ path: `lego/checklist/${checklist.id}/rename/${checklist.newTitle}`})
        
        if( !response.ok ) {
          commit('renameChecklist', {id: checklist.id, title: checklist.oldTitle})
          throw new Error('No se pudo renombrar el checklist')
        }

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    async updateItemInChecklist({rootGetters, commit, dispatch, state}, item ) {
      try {
        dispatch('assertErrorRoleNotCreator', state.card.createdBy )

        const response = await rootGetters.fetchPut({ path: `lego/UpdateItemInChecklist/${item.id}`, data: item})
        if( response.ok ){
          const newItem = await response.json()
          commit('updateItemInChecklist', newItem)
        } else {
          throw new Error('No se pudo actualizar el item')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },
    async SetIsDoneItemInChecklist({rootGetters, commit, dispatch}, item ) {
      try {
        dispatch('assertErrorRoleNotCollaborator')
        const response = await rootGetters.fetchPut({ path: `lego/SetIsDoneItemInChecklist/${item.id}`, data: item})
        if( response.ok ){
          const newItem = await response.json()
          commit('updateItemInChecklist', newItem)
        } else {
          throw new Error('No se pudo actualizar el item')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    async setIsDoneCard({rootGetters, commit}, card ) {
      try {
        const response = await rootGetters.fetchPut({ path: `lego/SetIsDoneCard/${card.id}`, data: card})
        if( response.ok ){
          const newCard = await response.json()
          commit('setIsDoneCard', newCard)
        } else {
          throw new Error('No se pudo actualizar la tarjeta')
        }
      } catch(error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    async setValidationCard({rootGetters, commit}, card ) {
      try {
        const response = await rootGetters.fetchPut({ path: `lego/SetValidationCard/${card.id}`, data: card})
        if( response.ok ){
          const newCard = await response.json()
          console.log( commit, newCard );
          // commit('setIsDoneCard', newCard)
        } else {
          throw new Error('No se pudo validar la tarea')
        }
      } catch(error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    async addColumn({rootGetters, commit}, column ) {
      try {
        const response = await rootGetters.fetchPost({ path: 'lego/CreateColumn', data: column})
        if( response.ok ) {
          const newColumn = await response.json()
          commit('pushColumn', newColumn)
        } else {
          throw new Error('No se pudo crear la columna')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    async addCard({rootGetters, commit}, card ) {
      try {
        const response = await rootGetters.fetchPost({ path: 'lego/CreateCard', data: card})
        if( response.ok ) {
          const newCard = await response.json()
          commit('pushCard', newCard)
        } else {
          throw new Error('No se pudo crear la tarjeta')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    async addSubCard({rootGetters, commit, dispatch}, card ) {
      // wrapperCatchError() // No funciona para esta acción
      try {
        dispatch('assertErrorRoleNotCollaborator')
        const response = await rootGetters.fetchPost({ path: 'lego/CreateCard', data: card})
        if( response.ok ) {
          const newCard = await response.json()
          commit('pushSubCard', newCard)
        } else {
          throw new Error('No se pudo crear la tarjeta')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    async addChecklist({rootGetters, commit, dispatch}, checklist ) {
      try {
        dispatch('assertErrorRoleNotCollaborator')
        const response = await rootGetters.fetchPost({ path: 'lego/CreateChecklist', data: checklist})
        if( response.ok ) {
          const newChecklist = await response.json()
          newChecklist.items = []
          commit('pushChecklist', newChecklist)
        } else {
          throw new Error('No se pudo crear el checklist')
        }
      } catch ( error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    async addItemInChecklist({rootGetters, commit, dispatch }, item ) {
      try {
        dispatch('assertErrorRoleNotCollaborator')
        const response = await rootGetters.fetchPost({ path: 'lego/CreateChecklistItem', data: item})
        if( response.ok ){
          const newItem = await response.json()
          commit('pushItemInChecklist', newItem)
        } else {
          throw new Error('No se pudo crear el item')
        }
      } catch ( error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    async addCommentInCard({rootGetters, commit, dispatch }, comment ) {
      try {
        dispatch('assertErrorRoleNotCollaborator')

        
        const response = await rootGetters.fetchPost({ path: 'lego/CreateComment', data: comment})
        if( response.ok ){
          const newComment = await response.json()
          commit('pushCommentInCard', newComment)
        } else {
          throw new Error('No se pudo crear el comentario')
        }

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    
    },

    async addAssignment({rootGetters, commit}, assignment) {
      try {
        const response = await rootGetters.fetchPost({ path: 'lego/AssignmentResponsibility', data: assignment})
        if( response.ok ){
          const newAssignment = await response.json()
          commit('pushAssignmentInCard', newAssignment)
        } else {
          throw new Error('No se pudo crear la asignación')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    async addAttachedFile({rootGetters, commit }, file) {
      try {
        const response = await rootGetters.fetchPost({ path: 'lego/CreateAttachedFileInCard', data: file})
        if( response.ok ){
          const resultFile = await response.json()
          commit('pushAttachedFileInCard', resultFile)
        } else {
          throw new Error('No se pudo crear la asignación')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    setItemInColumn({rootGetters, commit, state, dispatch}, { columnId, obj }) {

      wrapperCatchError( () => {
        dispatch('assertErrorRoleAuditorOrOwner')

        const column = state.board.columns.find( c => c.id == columnId )
        
        commit('setItemInColumn', { columnId, obj })
        
        Object.assign(column, obj)
        rootGetters.fetchPut({
          path: `Lego/UpdateColumn/${columnId}`,
          data: column
        })
      })

    },

    setItemInCard({rootGetters, commit, state, dispatch}, obj ) {
      wrapperCatchError( () => {
        dispatch('assertErrorRoleNotCollaborator')

        const card = {...state.card}
        Object.assign(card, obj)
        commit('setCard', card)
        
        rootGetters.fetchPut({
          path: `Lego/UpdateCard/${card.id}`,
          data: card,
        })
      })
    },
    async setAsyncItemInCard({rootGetters, commit}, card ) {
      try {
        
        const response = await rootGetters.fetchPut({
          path: `Lego/UpdateCard/${card.id}`,
          data: card
        })

        if( response.ok ) {
          commit('setCard', card)
        } else {
          throw new Error('No se pudo actualizar la propiedad')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        }) 
      }
    },
    setColumns({rootGetters, commit, dispatch}, columns) {
      wrapperCatchError( () => {
        dispatch('assertErrorRoleAuditorOrOwner')
        
        const promises = columns.map( (column, index) => {
          column.order = index
          return rootGetters.fetchPut({
            path: `Lego/UpdateColumn/${column.id}`,
            data: column
          })
        })
        Promise.all(promises)
          .then( () => {
            console.log("column move success");
            commit('setColumns', columns)
          })

      })

    },
    moveCard({state, rootGetters, commit, dispatch}, {cards, columnId} ) {
      wrapperCatchError( () => {
        dispatch('assertErrorRoleAuditorOrOwner')
        
        const promises = cards.map( (card, index) => {
          card.order = index
          card.legoColumnId = columnId
          return rootGetters.fetchPut({
            path: `Lego/MoveCard/${card.id}`,
            data: card
          })
        })
  
        const prevCards = state.board.columns.find( c => c.id == columnId ).cards
        commit('setCardsByColumnId', { columnId, cards })

        Promise.all(promises)
        .then( () => {
          console.log("card move success");
        })
        .catch( () => {
          commit('setCardsByColumnId', { columnId, prevCards })
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No se pudo mover la tarjeta'
          })
        })
  
      })
    },
    async deleteColumn({rootGetters, commit, state}, column) {
      await Swal.fire({
        title: `¿Desea eliminar la columnas?`,
        text: `La columnas ${column.title} será eliminado y no podrá recuperarse.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then( async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await rootGetters.fetchDelete({ path: `Lego/DeleteColumn/${column.id}`})
            if(response.ok) {
              const columns = state.board.columns.filter( c => c.id != column.id)
              commit('setColumns', columns)
            } else {
              throw new Error('No se pudo eliminar la columna')
            }
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.message
            })
          }
      }})

    },
    async deleteCard({rootGetters, commit, state}, card) {
      await Swal.fire({
        title: `¿Desea eliminar esta tarjeta?`,
        text: `La tarjeta ${card.title} será eliminado y no podrá recuperarse.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then( async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await rootGetters.fetchDelete({ path: `Lego/DeleteCard/${card.id}`})
            if(response.ok) {
              const column = state.board.columns.find( c => c.id == card.legoColumnId )
              const cards = column.cards.filter( c => c.id != card.id )
              commit('setCards', {column, cards})
            } else {
              throw new Error('No se pudo eliminar la columna')
            }
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.message
            })
          }
        }
      })
    },

    async deleteItemInChecklist({rootGetters, commit, dispatch, state}, item) {
      try {
        dispatch('assertErrorRoleNotCreator', state.card.createdBy )
        const response = await rootGetters.fetchDelete({ path: `Lego/DeleteChecklistItem/${item.id}`})
        if( response.ok ) {
          commit('deleteItemInChecklist', item)
        } else {
          throw new Error('No se pudo eliminar el item')
        }
      } catch ( error ) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    async deleteChecklist({rootGetters, commit, dispatch, state}, checklist) {
      try {
        dispatch('assertErrorRoleNotCreator', state.card.createdBy )
        const response = await rootGetters.fetchDelete({ path: `Lego/DeleteChecklist/${checklist.id}`})
        if( response.ok ) {
          commit('deleteChecklist', checklist)
        } else {
          throw new Error('No se pudo eliminar el checklist')
        }
      } catch ( error ) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    async deleteAssignment({rootGetters, commit }, assignment) {
      try {
        const response = await rootGetters.fetchDelete({ path: `Lego/DeleteAssignment/${assignment.id}`})
        if( response.ok ) {
          commit('deleteAssignment', assignment)
        } else {
          throw new Error('No se pudo eliminar la asignación')
        }
      } catch ( error ) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        })
      }
    },

    async getAllRecourse({rootGetters, commit}) {
      let response = await rootGetters.fetchGet({ path: `Lego/Auditoria`})
      let auditoriaOptions = await response.json()
      auditoriaOptions.unshift({ id: null, name: 'No aplica' })
      commit('setAuditoriaOptions', auditoriaOptions)
    }


  },
  getters: {
    getRoleInBoard() {
      return ( userId, collaborators) => {
        try {
          const thisUser = collaborators.find( c => c.personaId == userId )
          return thisUser.role
        } catch (error) {
          return {
            id: 'F838CBF3-16BF-402D-BCDE-638EDAF8E4D5',
            name: 'Invitado',
            level: 4
          }
        }
      }
    },
    
  }
}

export default store;