import store from "@/store";

export default () => {
  const { userLoggedIn } = store.getters;
  const userLocalStorage = JSON.parse(localStorage.setPersonaAct);
  // console.log("userLoggedIn ", userLoggedIn);
  if (!userLoggedIn) {
    store.commit("setUserLoggedIn", userLocalStorage);
  }
};
