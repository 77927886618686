import peligrosYRiesgosData from "./peligrosYRiesgosData";
// const data = require('./peligrosYRiesgosData')
// this.peligros = data.default
// this.selectedPeligro = this.peligros[0]

export default {
  state: {
    data: null,
    selectedPeligroTitle: "",
    peligroIsDisabled: true,
    formJobSearch: {
      EmpresaId: null,
      SucursalId: null,
      DepartamentoId: null,
      AreaId: null,
      CargoId: null,
      CreadorId: null,
      NumeroHorasLaboralesAlDia: 0,
      NumeroTrabajadores: 0,
    },
    formOcurrenciaYEfectos: {},
    controles: [],
  },
  mutations: {
    setData(state, data) {
      state.data = data;
    },
    setPeligroIsDisabled(state, value) {
      state.peligroIsDisabled = value;
    },
    setSelectedPeligroTitle(state, title) {
      state.selectedPeligroTitle = title;
    },
    updateFormJobSearch(state, { key, id }) {
      state.formJobSearch[key] = id;
    },
    updateModelOcurrencia(state, value) {
      state.formOcurrenciaYEfectos.probabilidad = value.probabilidad;
      state.formOcurrenciaYEfectos.exposicion = value.exposicion;
    },

    updateModelEfectos(state, data) {
      state.formOcurrenciaYEfectos.efectos.forEach((efecto) => {
        let query = data.find((item) => item.efecto == efecto.name);
        if (query) {
          efecto.consecuencia = query.consecuencia;
          efecto.requisitoLegal = query.esRequisitoLegal;
          efecto.descripcion = query.descripcion;
        }
      });
    },
    updateModelControles(state, data) {
      state.controles = [...data];
    },
    resetPeligroMopdels(state) {
      (state.selectedPeligroTitle = "mecanico"),
        (state.controles = []),
        (state.formOcurrenciaYEfectos = {
          probabilidad: null,
          exposicion: null,
          efectos: [
            {
              title: "Salud",
              name: "salud",
              consecuencia: null,
              requisitoLegal: "si",
              descripcion: null,
            },
            {
              title: "Medio",
              name: "medio",
              consecuencia: null,
              requisitoLegal: "si",
              descripcion: null,
            },
            {
              title: "Producción",
              name: "produccion",
              consecuencia: null,
              requisitoLegal: "si",
              descripcion: null,
            },
            {
              title: "Imagen",
              name: "imagen",
              consecuencia: null,
              requisitoLegal: "si",
              descripcion: null,
            },
            {
              title: "Económico",
              name: "economico",
              consecuencia: null,
              requisitoLegal: "si",
              descripcion: null,
            },
          ],
        });
    },
  },
  actions: {
    initData({ commit, getters }) {
      let aux = peligrosYRiesgosData.map((peligro) => {
        peligro.descripcion = getters.saniticesText(peligro.descripcion);
        peligro.funteDelPeligro = getters.saniticesText(
          peligro.funteDelPeligro
        );
        return peligro;
      });
      commit("setData", aux);
      commit("setSelectedPeligroTitle", aux[0].title);
      commit("resetPeligroMopdels");
    },
    evalModelRequired({ getters }) {
      const currencyModel = getters.getFormOcurrenciaYEfectos;

      for (const key in currencyModel) {
        if (currencyModel[key] == null) {
          return [false, `Falta llenar el campo ${key}`];
        }

        if (typeof currencyModel[key] == "object") {
          for (const key2 in currencyModel[key]) {
            for (const key3 in currencyModel[key][key2]) {
              if (currencyModel[key][key2][key3] == null) {
                return [
                  false,
                  `Falta llenar el campo ${key3} en ${currencyModel[key][key2]["title"]} de ${key}`,
                ];
              }
            }
          }
        }
      }
      return [true, "ok"];
    },
    setPeligroIsDisabled({ commit }, value) {
      commit("setPeligroIsDisabled", value);
    },
    setSelectedPeligroTitle({ commit }, title) {
      commit("setSelectedPeligroTitle", title);
    },
    async initQuerys({ state, dispatch, commit }) {
      const { id: peligroId } = await dispatch("get", {
        path: `Peligro/${state.selectedPeligroTitle}`,
      });
      const { id: registroId } = await dispatch("findRegistro");
      // console.log("Registro: ", registroId)
      // console.log("Peligro: ", peligroId)

      dispatch("get", {
        path: `PeligroOcurrencia/${peligroId}/${registroId}`,
      }).then((query) => {
        let queryOcurrencia = query.traceId ? null : query;
        if (queryOcurrencia != null) {
          commit("updateModelOcurrencia", queryOcurrencia);
        }
      });

      dispatch("get", {
        path: `PeligroEfecto/${peligroId}/${registroId}`,
      }).then((query) => {
        let efectos = query.traceId ? null : query;
        if (efectos.length) {
          commit("updateModelEfectos", efectos);
        }
      });

      dispatch("get", {
        path: `PeligroControl/${peligroId}/${registroId}`,
      }).then((query) => {
        let controles = query.traceId ? null : query;
        if (controles.length) {
          commit("updateModelControles", controles);
        }
      });
    },

    saveAboutPeligro({ dispatch, commit }) {
      //Validar que todo campo este lleno
      //se prefirió validar en la vista principal

      //Guardar
      dispatch("saveOcurrencia");
      dispatch("saveEfectos");
      dispatch("saveControles");

      //reset
      commit("resetPeligroMopdels");
    },

    setDataFromBackend({ dispatch }) {
      dispatch("initQuerys");
    },

    //Esto se hace con efectos y controles, ademas se debe corregir el componente de controles.
    async saveOcurrencia({ dispatch, getters }) {
      const peligroTitle = getters.getSelectedPeligroTitle;
      const currencyModel = getters.getFormOcurrenciaYEfectos;

      const { id: peligroId } = await dispatch("get", {
        path: `Peligro/${peligroTitle}`,
      });
      const { id: registroId } = await dispatch("findRegistro");
      // console.log("Registro: ", registroId)
      // console.log("Peligro: ", peligroId)

      let queryOcurrencia = await dispatch("get", {
        path: `PeligroOcurrencia/${peligroId}/${registroId}`,
      });
      queryOcurrencia = queryOcurrencia.traceId ? null : queryOcurrencia;

      // console.log("Ocurrencia: ", queryOcurrencia);
      if (queryOcurrencia == null) {
        //post
        let model = {
          PeligroId: peligroId,
          PeligroIdentificadorPeligroId: registroId,
          Probabilidad: Number(currencyModel.probabilidad),
          Exposicion: Number(currencyModel.exposicion),
        };
        // console.log("model: ", model);
        dispatch("post", { path: `PeligroOcurrencia`, data: model });
      } else {
        //put // tener en cuenta si nada cambio.
        let model = {
          Probabilidad: Number(currencyModel.probabilidad),
          Exposicion: Number(currencyModel.exposicion),
        };
        dispatch("put", {
          path: `PeligroOcurrencia/${queryOcurrencia.id}`,
          data: model,
        });
      }
    },

    async saveEfectos({ dispatch, getters }) {
      const peligroTitle = getters.getSelectedPeligroTitle;
      const currencyModel = getters.getFormOcurrenciaYEfectos;

      const { id: peligroId } = await dispatch("get", {
        path: `Peligro/${peligroTitle}`,
      });
      const { id: registroId } = await dispatch("findRegistro");
      // console.log("Registro: ", registroId);
      // console.log("Peligro: ", peligroId);

      let queryEfectos = await dispatch("get", {
        path: `PeligroEfecto/${peligroId}/${registroId}`,
      });

      if (!queryEfectos.length) {
        //post all efectos per peligro
        currencyModel.efectos.forEach((efecto) => {
          let modelo = {
            PeligroId: peligroId,
            PeligroIdentificadorPeligroId: registroId,
            Consecuencia: efecto.consecuencia,
            Descripcion: efecto.descripcion,
            EsRequisitoLegal: efecto.requisitoLegal,
            Efecto: efecto.name,
          };
          dispatch("post", { path: `PeligroEfecto`, data: modelo });
        });
      } else {
        //put
        queryEfectos.forEach((efecto) => {
          let efectoModel = currencyModel.efectos.find(
            (item) => item.name == efecto.efecto
          );
          let modelo = {
            Consecuencia: efectoModel.consecuencia,
            Descripcion: efectoModel.descripcion,
            EsRequisitoLegal: efectoModel.requisitoLegal,
            Efecto: efectoModel.name,
          };
          dispatch("put", { path: `PeligroEfecto/${efecto.id}`, data: modelo });
        });
      }
    },
    async saveControles({ dispatch, getters }) {
      const peligroTitle = getters.getSelectedPeligroTitle;
      const currencyModel = getters.getControles;

      const { id: peligroId } = await dispatch("get", {
        path: `Peligro/${peligroTitle}`,
      });
      const { id: registroId } = await dispatch("findRegistro");

      let queryControles = await dispatch("get", {
        path: `PeligroControl/${peligroId}/${registroId}`,
      });

      if (!queryControles.length) {
        //post
        currencyModel.forEach(async (control) => {
          let modelo = {
            PeligroId: peligroId,
            PeligroIdentificadorPeligroId: registroId,
            Estado: control.estado,
            Descripcion: control.descripcion,
            Tipo: control.tipo,
          };
          await dispatch("post", { path: `PeligroControl`, data: modelo });
        });
      } else {
        //put
        currencyModel.forEach(async (control) => {
          if (control.id) {
            let model = {
              Estado: control.estado,
              Descripcion: control.descripcion,
              Tipo: control.tipo,
            };
            dispatch("put", {
              path: `PeligroControl/${control.id}`,
              data: model,
            });
          } else {
            let modelo = {
              PeligroId: peligroId,
              PeligroIdentificadorPeligroId: registroId,
              Estado: control.estado,
              Descripcion: control.descripcion,
              Tipo: control.tipo,
            };
            dispatch("post", { path: `PeligroControl`, data: modelo });
          }
        });
      }
    },

    async findRegistro({ state, dispatch }) {
      let model = state.formJobSearch;
      const query = await dispatch("get", {
        path: `PeligroIdentificadorPeligro/${model.EmpresaId}/${model.SucursalId}/${model.DepartamentoId}/${model.AreaId}/${model.CargoId}`,
      });
      return query.traceId ? null : query;
    },
  },
  getters: {
    saniticesText() {
      return (text) => {
        return text
          .split(" ")
          .map((word, index, array) => {
            word = word.toLowerCase();
            const wordBefore = index > 0 ? array[index - 1] : "";
            if (index == 0 || wordBefore[wordBefore.length - 1] == ".")
              return (
                word.substring(0, 1).toUpperCase() +
                word.substring(1, word.length)
              );
            if (index == array.length - 1)
              return word[word.length - 1] == "." ? word : word + ".";
            return word;
          })
          .join(" ");
      };
    },
    getPeligroIsDisabled(state) {
      return state.peligroIsDisabled;
    },
    getPeligro(state) {
      return state.data.find(
        (peligro) => peligro.title == state.selectedPeligroTitle
      );
    },
    getSelectedPeligroTitle(state) {
      return state.selectedPeligroTitle;
    },
    getItemJobSearch(state) {
      return (key) => {
        return state.formJobSearch[key];
      };
    },
    getFormOcurrenciaYEfectos(state) {
      return state.formOcurrenciaYEfectos;
    },
    getControles(state) {
      return state.controles;
    },
  },
};
