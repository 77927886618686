
import { hojaDeVidaVehiculoState, hojaDeVidaVehiculoActions } from './schemasSplice/hojaDeVidaVehiculoSchemas.js'
import { reclutamientoYSeleccionState, reclutamientoYSeleccionActions } from './schemasSplice/reclutamientoYSeleccionSchemas.js'


export default {
    state: {
        ...hojaDeVidaVehiculoState,
        ...reclutamientoYSeleccionState,
    },
    actions: {
        ...hojaDeVidaVehiculoActions,
        ...reclutamientoYSeleccionActions,

    },
}