<script>
// import ModeSwitch from  "@/components/mode/ModeSwitch.vue";
// import AssistantWrapper from  "@/components/assistant/AssistantWrapper.vue";

export default {
  components: {
    // ModeSwitch,
    ButtonSetTheme: () => import('@/components/mode/ButtonSetTheme.vue'),
    // AssistantWrapper
  },
  computed: {
    PESVIABot() {
      return this.$route.path.toLowerCase().includes('pesv')
    },
    SGSSTBot() {
      return this.$route.path.toLowerCase().includes('sgsst')
    },
  },
  data() {
    return {
      auxState: false
    }
  },
  mounted() {
    this.auxState = true
    this.$watch('$route', () => {
      this.auxState = false
      this.$nextTick(() => {
        this.auxState = true})
      }
    )
  }

}


</script>

<template>
    <div class="btn-flotante">
      <ButtonSetTheme />
      <!-- <ModeSwitch class=""></ModeSwitch> -->

      <div class="logo-WhatsApp">
        <i class="fa-brands fa-whatsapp p-1" ></i>
      </div>
      <!-- <template v-if="auxState">
        <AssistantWrapper v-if="PESVIABot" typeAssistant="PESV"/>
        <AssistantWrapper v-else-if="SGSSTBot" typeAssistant="SGSST"/>
        <AssistantWrapper v-else  typeAssistant="LABORAL"/>
      </template> -->
      <!-- <button href="" type="button" class="btn btn-success btn-circle btn-xl  " id="example1"><i class="fa fa-whatsapp h1"></i></button> -->
    </div>
</template>

<style scoped>
.logo-WhatsApp {
  background-color: #25D366;
  border-radius: 50%;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 22px;
  cursor: pointer;
  transition: .3s;
}
/* .logo-WhatsApp:hover  {
  color: rgb(255, 255, 255);
  box-shadow: inset 0 0 5px white;
} */

.btn-flotante {
  /* font-size: 16px; /* Cambiar el tamaño de la tipografia */
  /* text-transform: uppercase; /* Texto en mayusculas */
  /* font-weight: bold; /* Fuente en negrita o bold */
  /* color: #ffffff; /* Color del texto */
  /* border-radius: 5px; /* Borde del boton */
  /* letter-spacing: 2px; /* Espacio entre letras */
  /* /* background-color: #E91E63; /* Color de fondo */
  position: fixed;
  bottom: 10px;
  right: 17px;
  gap: .5rem;
  padding: 18px 0;
  transition: .3s;
  z-index: 999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 767px) {
  .btn-flotante {

  }
}
</style>