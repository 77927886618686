<template>
  <div>
    <router-view class="" />
    <!-- <a href="#" class="btn-flotante">Llamada a la acción</a> -->
    <!-- <div class="btn-flotante">
      <ModeSwitch class=""></ModeSwitch>
      <vs-button icon p-3 success gradient  size="xl" href="https://wa.me/+5719191001" target="_blank" class="" style="width: 45px; height: 45px; border-radius: 150px;">
        <i class="fa-brands fa-whatsapp p-1" style="font-size: 30px !important;"></i>
      </vs-button>
    </div> -->
    <RefExtras />
    <ChatsHelexium />
  </div>
</template>
<script>
import RefExtras from "@/components/RefExtras/RefExtras.vue";
import ChatsHelexium from "@/views/Helexium/Assistant/ChatsHelexium.vue";
// import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
// import 'remixicon/fonts/remixicon.css'
export default {
  sockets: {
    connect: function () {
      console.log("conectado a cockets. en app.vue***************************************************************");
    },
    nuevo_mensaje: async function (data) {
      let usuario = JSON.parse(localStorage.getItem("setPersonaAct")) || null;
      console.log(data);
      if (usuario != null) {
        if (data.ParaId == usuario.id) {
          // alert("has recibido respuesta en ticket");
          this.showToast("success", "Has recibido respuesta de ticket");

          //console.log(data)
          // Push.Permission.get()
          // Push.create('Orden sin driver asignado!', {
          //   //Titulo de la notificación
          //   body: data.descripcion, //Texto del cuerpo de la notificación
          //   icon: 'https://admin.appyaqui.com/img/yaqui.7c8b493b.jpg', //Icono de la notificación
          //   timeout: 6000, //Tiempo de duración de la notificación
          // })
        } else {
          if (data.ParaId == null && usuario.rol.nivel == 4) {
            this.showToast("success", "Admin!, tienes nuevo mensaje ticket!");
          }
        }
      }
    },
  },
  name: "App",
  data() {
    return {
      usuario: {},
    };
  },
  components: {
    RefExtras,
    ChatsHelexium,
  },
  mounted() {
    console.log("init app");
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    // let html = document.querySelector("html");
    // html.classList.add("skytheme");

    //console.log('cargando modo oscuro en mounted app')
  },
  methods: {
    showToast(type, message) {
      console.log(type);
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 2984,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1992,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      if (type == "warning")
        this.$toast.warning(message, {
          position: "top-center",
          timeout: 2984,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },
  },
};
</script>
<style lang="scss">
@import "./assets/scss/backend.scss";
@import "./assets/scss/frontend.scss";
@import "./assets/css/custom.css";
body {
  background-color: $bodybg;
  font-size: 12px 
  // background: linear-gradient(0deg, rgba(124, 134, 187, 1) 22%, rgb(220, 231, 255) 100%);
}
// .btn-circle.btn-xl {
//   width: 52px;
//   height: 52px;
//   padding: 0px 0px;
//   border-radius: 3px;
//   font-size: 16.5px;
//   line-height: 1.33;
// }

// /* @import "./assets/css/styles.css"; */
// .btn-success {
//   background-color: #1db856 !important;
//   border-color: #25d366;
// }

// .btn-success:hover {
//   background-color: #16a34a !important;
//   border-color: #7e817f;
// }

// .btn-success:focus {
//   background-color: #0a8a39 !important;
//   border-color: #0cb148;
// }

.pointer {
  cursor: pointer;
}

.zindexwhatsapp {
  z-index: 99999;
}
</style>
