export const hojaDeVidaVehiculoState = {
  hojaDeVidaVehiculoInformacionGeneral: [
    {
      validations: 'required',
      label: 'Placa',
      name: 'placa',
      type: 'text',
      span: 4,
      value: null,
    },
    {
      // validations: 'required',
      label: 'Licencia de transito Nro.',
      name: 'licenciaTransito',
      type: 'text',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Fecha de matricula',
      name: 'fechaMatricula',
      type: 'date',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Tipo de propietario',
      name: 'tipoAdquisicion',
      type: 'select',
      span: 4,
      value: null,
      options: [
        '', 'Propio', 'Vinculado', 'Tercero'
      ]
    },
    {
      // validations: 'required',
      label: 'Marca',
      name: 'marca',
      type: 'text',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Linea',
      name: 'linea',
      type: 'text',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Modelo',
      name: 'modelo',
      type: 'text',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Cilindraje',
      name: 'cilindraje',
      type: 'text',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Color',
      name: 'color',
      type: 'text',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Número de motor',
      name: 'numeroMotor',
      type: 'text',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Número de chasis',
      name: 'numeroChasis',
      type: 'text',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Clave de seguridad',
      name: 'claveSeguridad',
      type: 'text',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Blindado',
      name: 'blindado',
      type: 'select',
      span: 4,
      value: null,
      options: ['Si', 'No']
    },
    {
      // validations: 'required',
      label: 'Fecha de blindado',
      name: 'fechaBlindado',
      type: 'date',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Declaración de importación',
      name: 'declaracionImportacion',
      type: 'select',
      span: 4,
      value: null,
      options: ['Si', 'No']
    },
    {
      // validations: 'required',
      label: 'Fecha de declaración de importación',
      name: 'fechaDeclaracionImportacion',
      type: 'date',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Tipo de dirección',
      name: 'tipoDireccion',
      type: 'select',
      span: 4,
      value: null,
      options: ['Hidráulica', 'Semiasistida mecánica'],
    },
    {
      // validations: 'required',
      label: 'Capacidad de carga',
      name: 'capacidadCarga',
      type: 'text',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Numero de pasajeros',
      name: 'numeroPasajeros',
      type: 'number',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Estado del vehículo',
      name: 'estadoVehiculo',
      type: 'select',
      span: 4,
      value: null,
      options: ['Excelente', 'Bueno', 'Regular', 'Malo'],
    },
    {
      // validations: 'required',
      label: 'Motor reparado',
      name: 'motorReparado',
      type: 'select',
      span: 4,
      value: null,
      options: ['Si', 'No']
    },
    {
      // validations: 'required',
      label: 'Fecha de motor reparado',
      name: 'fechaMotorReparado',
      type: 'date',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Numero de asientos',
      name: 'numeroAsientos',
      type: 'number',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Numero de puertas',
      name: 'numeroPuertas',
      type: 'number',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Frenos delanteros',
      name: 'frenosDelanteros',
      type: 'select',
      span: 4,
      value: null,
      options: ['Si', 'No']
    },
    {
      // validations: 'required',
      label: 'Frenos traseros',
      name: 'frenosTraseros',
      type: 'select',
      span: 4,
      value: null,
      options: ['Si', 'No']
    },
    {
      // validations: 'required',
      label: 'Tipo de servicio',
      name: 'tipoDeServicioId',
      type: 'select',
      span: 4,
      value: null,
      options: [],
    },
    {
      // validations: 'required',
      label: 'Tipo de vehículo',
      name: 'tipoDeVehiculoId',
      type: 'select',
      span: 4,
      value: null,
      options: [],
    },
    {
      // validations: 'required',
      label: 'Tipo de energía empleada',
      name: 'tipoDeEnergiaEmpleadaId',
      type: 'select',
      span: 4,
      value: null,
      options: [],
    },
  ],
  hojaDeVidaVehiculoConductorAsignado: [
    {
      // validations: 'required',
      label: 'Fecha de recibo',
      name: 'fechaRecibo',
      type: 'date',
      span: 4,
      value: null
    },
    {
      //Traer de base de datos nombres a pellidos de SQL 
      // validations: 'required',
      label: 'Nombres y apellidos del conductor',
      name: 'nombresApellidosConductorId',
      type: 'select',
      span: 4,
      value: null,
      options: []
    },
    {
      // validations: 'required',
      label: 'No DOC identidad del conductor',
      name: 'noDocIdentidadConductor',
      type: 'text',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Labor asignada',
      name: 'laborAsignada',
      type: 'text',
      span: 4,
      value: null
    },
    {
      // validations: 'required',
      label: 'Motivo de cambio conductor',
      name: 'motivoCambioConductor',
      type: 'text',
      span: 4,
      value: null
    },
    {
      validations: '',
      label: 'Observaciones',
      name: 'observaciones',
      type: 'text',
      span: 4,
      value: null,
    }
  ],
  hojaDeVidaVehiculoMantenimientosRealizados: [
    {
      label: 'Numero de orden',
      // validations: 'required',
      name: 'numeroOrden',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Fecha',
      // validations: 'required',
      name: 'fecha',
      type: 'date',
      span: 4,
      value: null
    },
    {
      label: 'Proveedor',
      // validations: 'required',
      name: 'nombreProveedor',
      type: 'select',
      span: 4,
      value: null,
      options: ['Persona natural', 'Persona jurídica']
    },
    {
      label: 'Numero de identificación o NIT',
      // validations: 'required',
      name: 'numeroIdentificacion',
      type: 'text',
      span: 4,
      value: null,
      state: 'disabled'
    },
    {
      label: 'Kilometraje del vehiculo',
      // validations: 'required',
      name: 'kilometrajeVehiculo',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Tipo de mantenimiento',
      // validations: 'required',
      name: 'tipoMantenimiento',
      type: 'select',
      span: 4,
      value: null,
      options: [

        {
          value: 'preventivo',
          label: 'Preventivo'
        },
        {
          value: 'correctivo',
          label: 'Correctivo'
        }
      ]
    },
    {
      label: 'Requiere',
      // validations: 'required',
      name: 'requiere',
      type: 'select',
      span: 4,
      value: null,
      options: [
        {
          value: 'desmontar',
          label: 'Desmontar'
        },
        {
          value: 'cambiar',
          label: 'Cambiar'
        },
        {
          value: 'arreglar',
          label: 'Arreglar'
        }
      ]
    },
    {
      label: 'Descripción del mantenimiento',
      // validations: 'required',
      name: 'descripcionMantenimiento',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Conductor asignado',
      // validations: 'required',
      name: 'conductorAsignadoId',
      type: 'select',
      span: 4,
      value: null,
      options: []
    },
    {
      label: 'Valor',
      name: 'ValorDelMantenimiento',
      type: 'text',
      span: 4,
      value: null,

    }
  ],
  hojaDeVidaVehiculoIncidentesYAccidentes: [
    {
      label: 'Fecha',
      // validations: 'required',
      name: 'fecha',
      type: 'date',
      span: 4,
      value: null
    },
    {
      label: 'Hora',
      // validations: 'required',
      name: 'hora',
      type: 'time',
      span: 4,
      value: null
    },
    {
      label: 'Tipo de evento',
      // validations: 'required',
      name: 'tipoDeEvento',
      type: 'text',
      span: 4,
      value: null,
    },
    {

      // select
      label: 'Nombres y apellidos del conductor',
      // validations: 'required',
      name: 'nombresApellidosDelConductorId',
      type: 'select',
      span: 4,
      value: null,
      options: []
    },
    {
      // Cedula no modificable 
      label: 'Cedula del conductor',
      // validations: 'required',
      name: 'cedulaDelConductor',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Tipo de accidente',
      // validations: 'required',
      name: 'tipoDeAccidente',
      type: 'text',
      span: 4,
      value: null,
    },
    {
      label: 'Afectación a',
      // validations: 'required',
      name: 'afectacionA',
      type: 'text',
      span: 4,
      value: null,
    },
    {
      label: 'Tipo de lesiones más comunes en afectaciones a personas',
      // validations: 'required',
      name: 'tipoDeLesionesMasComunesEnAfectacionesAPersonas',
      type: 'text',
      span: 4,
      value: null,
    },
    {
      label: 'Afectación económica a cargo de',
      // validations: 'required',
      name: 'afectacionEconomicaACargoDe',
      type: 'text',
      span: 4,
      value: null,
    },
    {
      label: 'Afectación económica valor',
      // validations: 'required',
      name: 'afectacionEconomicaValor',
      type: 'text',
      span: 4,
      value: null,
    },

  ],
  hojaDeVidaVehiculoSoat: [
    {
      label: 'Fecha de expedición',
      // validations: 'required',
      name: 'fechaExpedicion',
      type: 'date',
      span: 4,
      value: null
    },
    {
      label: 'Tomador del soat',
      name: 'tomadorSoat',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Nit o CC',
      name: 'nitCc',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Número de poliza',
      name: 'noPoliza',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Fecha de inicio de vigencia',
      // validations: 'required',
      name: 'fechaInicioVigencia',
      type: 'date',
      span: 4,
      value: null
    },
    {
      label: 'Fecha de fin de vigencia',
      // validations: 'required',
      name: 'fechaFinVigencia',
      type: 'date',
      span: 4,
      value: null
    },
    {
      label: 'Entidad que expide',
      name: 'entidadExpide',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Estado',
      // validations: 'required',
      name: 'estado',
      type: 'select',
      span: 4,
      value: null,
      options: [
        {
          label: 'Activo',
          value: 'activo'
        },
        {
          label: 'Inactivo',
          value: 'inactivo'
        }
      ],
    },
  ],
  hojaDeVidaVehiculoPoliza: [
    {
      label: 'Fecha de expedición',
      // validations: 'required',
      name: 'fechaExpedicion',
      type: 'date',
      span: 4,
      value: null
    },
    {
      label: 'Número de poliza',
      name: 'noPoliza',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Fecha de inicio de vigencia',
      // validations: 'required',
      name: 'fechaInicioVigencia',
      type: 'date',
      span: 4,
      value: null
    },
    {
      label: 'Fecha de fin de vigencia',
      // validations: 'required',
      name: 'fechaFinVigencia',
      type: 'date',
      span: 4,
      value: null
    },
    {
      label: 'Entidad que expide',
      name: 'entidadExpide',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Estado',
      // validations: 'required',
      name: 'estado',
      type: 'select',
      span: 4,
      value: null,
      options: [
        {
          label: 'Activo',
          value: 'activo'
        },
        {
          label: 'Inactivo',
          value: 'inactivo'
        }
      ],
    },
  ],
  hojaDeVidaVehiculoTecnomecanica: [
    {
      label: 'Fecha de expedición',
      // validations: 'required',
      name: 'fechaExpedicion',
      type: 'date',
      span: 4,
      value: null
    },
    {
      label: 'Número de certificado',
      name: 'noCertificado',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Fecha de inicio de vigencia',
      // validations: 'required',
      name: 'fechaInicioVigencia',
      type: 'date',
      span: 4,
      value: null
    },
    {
      label: 'Fecha de fin de vigencia',
      // validations: 'required',
      name: 'fechaFinVigencia',
      type: 'date',
      span: 4,
      value: null
    },
    {
      label: 'Entidad que expide',
      name: 'entidadExpide',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Estado',
      // validations: 'required',
      name: 'estado',
      type: 'select',
      span: 4,
      value: null,
      options: [
        {
          label: 'Activo',
          value: 'activo'
        },
        {
          label: 'Inactivo',
          value: 'inactivo'
        }
      ],
    },
  ],
  hojaDeVidaVehiculoImpuestos: [
    {
      label: 'Tramitados',
      // validations: 'required',
      name: 'tramitados',
      type: 'select',
      span: 4,
      value: null,
      options: [
        {
          label: 'Si',
          value: 'si'
        },
        {
          label: 'No',
          value: 'no'
        }
      ],
    },
    {
      label: 'Pagados',
      // validations: 'required',
      name: 'pagados',
      type: 'select',
      span: 4,
      value: null,
      options: [
        {
          label: 'Si',
          value: 'si'
        },
        {
          label: 'No',
          value: 'no'
        }
      ],
    },
    {
      label: 'Fecha',
      // validations: 'required',
      name: 'fecha',
      type: 'date',
      span: 4,
      value: null
    },
    {
      label: 'Número de referencia de recaudo',
      // validations: 'required',
      name: 'noReferenciaRecaudo',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Número de factura',
      // validations: 'required',
      name: 'noFactura',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Valor pagado',
      // validations: 'required',
      name: 'valorPagado',
      type: 'text',
      span: 4,
      value: null
    },
  ],
  hojaDeVidaVehiculoComparendo: [
    {
      label: 'Número',
      // validations: 'required',
      name: 'numero',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Tipo de infractor',
      // validations: 'required',
      name: 'tipoDeInfractor',
      type: 'text',
      span: 4,
      value: null
    },
    {
      label: 'Inmovilización',
      // validations: 'required',
      name: 'inmovilizacion',
      type: 'select',
      span: 4,
      value: null,
      options: [
        {
          label: 'Si',
          value: 'si'
        },
        {
          label: 'No',
          value: 'no'
        }
      ],
    },
    {
      label: 'Estado',
      // validations: 'required',
      name: 'estado',
      type: 'select',
      span: 4,
      value: null,
      options: [
        {
          label: 'Activo',
          value: 'activo'
        },
        {
          label: 'Inactivo',
          value: 'inactivo'
        }
      ],
    },
    {
      label: 'Observaciones',
      // validations: 'required',
      name: 'observaciones',
      type: 'text',
      span: 4,
      value: null
    },
  ],
  tarjetaDeOperaciones: [
    {
      label: 'Fecha de expedición',
      name: 'fechaExpedicion',
      validations: 'required',
      type: 'date',
      value: null
    },
    {
      label: 'Fecha de vencimiento',
      name: 'fechaVencimiento',
      validations: 'required',
      type: 'date',
      value: null
    },
    {
      label: 'Número de tarjeta',
      name: 'numeroTarjeta',
      validations: 'required',
      type: 'text',
      value: ''
    },
    {
      label: 'Empresa afiliadora',
      name: 'empresaAfiliadora',
      type: 'text',
      value: ''
    },
  ],
  propietarioDelVehiculo: [
    {
      label: 'Propietario',
      name: 'personaId',
      validations: 'required',
      type: 'select',
      value: null,
      options: []
    },
    {
      label: 'Identificador del propietario',
      name: 'identificadorPropietario',
      validations: 'required',
      type: 'text',
      value: ''
    },
  ]
}

export const hojaDeVidaVehiculoActions = {
  async getHojaDeVidaVehiculoInformacionGeneral({ state, dispatch }) {
    const schema = state.hojaDeVidaVehiculoInformacionGeneral

    let index = schema.findIndex(item => item.name === 'tipoDeServicioId')
    schema[index].options = await dispatch('getTiposDeServicio')

    index = schema.findIndex(item => item.name === 'tipoDeVehiculoId')
    schema[index].options = await dispatch('getTiposDeVehiculo')

    index = schema.findIndex(item => item.name === 'tipoDeEnergiaEmpleadaId')
    schema[index].options = await dispatch('getTiposDeEnergiaEmpleada')
    return schema
  },

  async getHojaDeVidaVehiculoConductorAsignado({ state, dispatch }, empresaId) {
    const schema = state.hojaDeVidaVehiculoConductorAsignado
    schema[1].options = await dispatch('getPersonasDeLaEmpresa', empresaId)
    return schema
  },

  async getHojaDeVidaVehiculoMantenimientosRealizados({ state, dispatch }, empresaId) {
    const schema = state.hojaDeVidaVehiculoMantenimientosRealizados
    schema[8].options = await dispatch('getPersonasDeLaEmpresa', empresaId)
    return schema
  },

  async getHojaDeVidaVehiculoIncidentesYAccidentes({ state, dispatch }, empresaId) {
    const schema = state.hojaDeVidaVehiculoIncidentesYAccidentes
    schema[3].options = await dispatch('getPersonasDeLaEmpresa', empresaId)
    return schema
  },

  async getHojaDeVidaVehiculoSoat({ state }) {
    const schema = state.hojaDeVidaVehiculoSoat
    return schema
  },

  async getHojaDeVidaVehiculoPoliza({ state }) {
    const schema = state.hojaDeVidaVehiculoPoliza
    return schema
  },

  async getHojaDeVidaVehiculoTecnomecanica({ state }) {
    const schema = state.hojaDeVidaVehiculoTecnomecanica
    return schema
  },

  async getHojaDeVidaVehiculoImpuestos({ state }) {
    const schema = state.hojaDeVidaVehiculoImpuestos
    return schema
  },

  async getHojaDeVidaVehiculoComparendo({ state }) {
    const schema = state.hojaDeVidaVehiculoComparendo
    return schema
  },
  async getTarjetaDeOperaciones({ state }) {
    const schema = state.tarjetaDeOperaciones
    return schema
  },

  async getPropietarioDelVehiculo({ state, dispatch }, empresaId) {
    const schema = state.propietarioDelVehiculo
    schema[0].options = await dispatch('getPersonasDeLaEmpresa', empresaId)
    return schema
  },
}