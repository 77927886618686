
export default [
    { 
        "title": "mecanico", 
        "text": "Mecánico",
        "descripcion": "Caidas mismo nivel, golpes", 
        "funteDelPeligro": "Manipulación de herramientas de oficina,  muebles mal ubicados, tránsito por zonas donde hay vehículos en movimiento", 
        "probabilidad": 0,
        "exposicion": 0,
        "efectosYOcurrencia": [
            { "descripcion": "", "title": "salud", "text": "Salud", "ejemplo": "Golpes, heridas, traumas superficiales en brazos y piernas,  lesiones en ojos(lesiones con necesidad de atencion médica).", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "medio", "text": "Medio", "ejemplo": "No afecta", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "produccion", "text": "Producción", "ejemplo": "RETRASA LA OPERACIÓN MAS DE 6 HORAS", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "imagen", "text": "Imagen", "ejemplo": "SI SOLO ES DE CONOCIMIENTO DE PERSONAL DE PROCESO", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "economico", "text": "Económico", "ejemplo": "LEVE", "requisitoLegal": "si", "consecuencia": "Number"}
        ],
        "controles": [
            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "", "tipo": "existentes"},

            { "descripcion": "", "title": "eliminacion", "text": "Eliminación",  "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería",  "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio",  "ejemplo": "DESARROLLAR   ACTIVIDADES DE SENSIBILIZACION PARA CONTROL DEL RIESGO Y LA GENERACION DE ACTOS INSEGUROS", "tipo": "recomendados"},
            { "descripcion": "", "title": "individuo", "text": "Individuo",  "ejemplo": "ESTIMULAR  REPORTE  DE CONDICIONES DE PELIGRO Y EL CONTROL DE ACTOS INSEGUROS.", "tipo": "recomendados"}
        ]
    },
    { 
        "title": "electrico", 
        "text": "Eléctrico",
        "descripcion": "Descargas eléctricas de baja tensión", 
        "funteDelPeligro": "Baja tensión, Conexiones eléctricas", 
        "probabilidad": 0,
        "exposicion": 0,
        "efectosYOcurrencia": [
            { "descripcion": "", "title": "salud", "text": "Salud", "ejemplo": "TRAUMAS, HERIDAS, CONTUSIONES, QUEMADURAS, GOLPES.", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "medio", "text": "Medio", "ejemplo": "No afecta", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "produccion", "text": "Producción", "ejemplo": "RETRASA LA OPERACIÓN MAS DE 6 HORAS", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "imagen", "text": "Imagen", "ejemplo": "SOLO ES DE CONOCIMIENTO DEL PERSONAL DEL PROCESO", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "economico", "text": "Económico", "ejemplo": "LEVE", "requisitoLegal": "si", "consecuencia": "Number"}
        ],
        "controles": [
            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "SISTEMAS ELECTRICOS REGULADOS Y  ENTUBADOS  Y CON POLO A TIERRA.", "tipo": "existentes"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "", "tipo": "existentes"},

            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "MANTENER CONTROLES EXISTENTES.", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": "REALIZAR CONTROLES DE VIGILANCIA Y CONTROL DE RIESGO . FORTALECER SEÑALIZACION Y DEMARCACION DE ADVERTENCIA DEL RIESGO.", "tipo": "recomendados"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "SENSIBILIZAR  AL PERSONALSOBRE REPORTE DE CONDICIONES DE PELIGRO.", "tipo": "recomendados"}
        ]
    },
    { 
        "title": "locativo", 
        "text": "Locativo",
        "descripcion": "Superficies y desplazamiento, caídas mismo nivel, golpes", 
        "funteDelPeligro": "Superficies de trabajo (Pisos  desnivel)  en malas condiciones, áreas de circulación insuficiente, muebles mal ubicados", 
        "probabilidad": 0,
        "exposicion": 0,
        "efectosYOcurrencia": [
            { "descripcion": "", "title": "salud", "text": "Salud", "ejemplo": "ESGUINCES, FRACTURAS, LUXACIONES, TRAUMAS, HERIDAS, CONTUSIONES, GOLPES.", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "medio", "text": "Medio", "ejemplo": "No afecta", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "produccion", "text": "Producción", "ejemplo": "RETRASA LA OPERACIÓN MAS DE 6 HORAS", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "imagen", "text": "Imagen", "ejemplo": "SOLO ES DE CONOCIMIENTO DEL PERSONAL DEL PROCESO", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "economico", "text": "Económico", "ejemplo": "LEVE", "requisitoLegal": "si", "consecuencia": "Number"}
        ],
        "controles": [
            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "ESCALERAS CON CINTA ANTIDESLIZANTE Y PASAMANOS,  ORDEN Y ASEO, SEÑALIZACION CONDICIONES DE PELIGRO.", "tipo": "existentes"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "", "tipo": "existentes"},

            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "EJECUTAR  MTTO PREVENTIVO Y CORRECTIVO DE LAS INSTALACIONES (PISOS)", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": " IMPLEMENTAR PROGRAMA DE ORDEN Y ASEO.", "tipo": "recomendados"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "PROGRAMA DE CAPACITACION Y SENSIBILIZACION AL RIESGO , FORTALECIMIENTO HABITOS DE ORDEN Y LIMPIEZA, SENSIBILIZAR AL TRABJADOR EN EL REPORTE DE SITUACIONES DE PELIGRO.", "tipo": "recomendados"}
        ]
    },
    { 
        "title": "antropicoODeOrdenPublico", 
        "text": "Antrópico o de orden público",
        "descripcion": "Agresiones físicas,  atraco - robos, secuestro, extorsiones, fleteo", 
        "funteDelPeligro": "Transporte por diferentes zonas de la ciudad ( reuniones laborales), condiciones de seguridad por orden público, atracos, robos, secuestros, accidentes  de tránsito, conflictos con personal de trabajo y clientes", 
        "probabilidad": 0,
        "exposicion": 0,
        "efectosYOcurrencia": [
            { "descripcion": "", "title": "salud", "text": "Salud", "ejemplo": "GOLPES, FRACTURAS, CONTUSIONES, MUERTE, TRAUMAS MENTALES, ESQUIZOFRENIA, ANGUSTIA, ANSIEDAD, ESTRÉS , MUERTE.", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "medio", "text": "Medio", "ejemplo": "No afecta", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "produccion", "text": "Producción", "ejemplo": "RETRASA LA OPERACIÓN POR MAS DE 12 HORAS", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "imagen", "text": "Imagen", "ejemplo": "ES DE CONOCIMIENTO DE AUTORIDADES DE CONTROL", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "economico", "text": "Económico", "ejemplo": "CRITICO", "requisitoLegal": "si", "consecuencia": "Number"}
        ],
        "controles": [
            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "EN EL PROCESO DE SELECCIÓN SE EVALUAN ANTECEDENTES JUDICIALES ", "tipo": "existentes"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "", "tipo": "existentes"},

            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "COLABORACION DE LA POLICIA, SENSIBILIZACION PARA DENUNCIAR PERSONAS SOSPECHOSAS.", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": "ESTABLECER PROTOCOLODE RIESGO PUBLICO, SENSIBILIZAR   Y CAPACITAR SOBRE RIESGO PUBLICO Y BUEN TRATO A NIVEL LABORAL E INTRAFAMILIAR. ", "tipo": "recomendados"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "DIVULGAR LOS PELIGROS PARTICULARES IDENTIFICADOS EN LA ZONA ESTABLECIENDO COMPORTAMIENTOS PREVENTIVOS. CAPACITACION EN RIESGO PUBLICO.", "tipo": "recomendados"}
        ]
    },
    { 
        "title": "radiacionesNoIonizantes", 
        "text": "Radiaciones no ionizantes",
        "descripcion": "", 
        "funteDelPeligro": "Radiaciones no ionizantes provenientes de lamparas fluorescentes, fotocopiadora, y computador.", 
        "probabilidad": 0,
        "exposicion": 0,
        "efectosYOcurrencia": [
            { "descripcion": "", "title": "salud", "text": "Salud", "ejemplo": "CEFALEA, FATIGA VISUAL,  SINDROME DE OJO SECO , REACCIONES ALERGICAS, CANSANCIO.", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "medio", "text": "Medio", "ejemplo": "No afecta", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "produccion", "text": "Producción", "ejemplo": "No afecta EL DESARROLLO DE LA OPERACIÓN.", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "imagen", "text": "Imagen", "ejemplo": "SI SOLO ES DE CONOCIMIENTO DE PERSONAL DE PROCESO", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "economico", "text": "Económico", "ejemplo": "LEVE", "requisitoLegal": "si", "consecuencia": "Number"}
        ],
        "controles": [
            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": "REALIZAN EXAMENES MEDICOS DE INGRESO (OPTOMETRIA)", "tipo": "existentes"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "SENSIBILIZACION AUTOCUIDADO Y PREVENCION DE ENFERMEDADES VISUALES, EXAMENES DE INGRESO (OPTOMETRIA).", "tipo": "existentes"},

            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": "REALIZAR CONTROL MEDICO DE INGRESO Y PERIODICOS (OPTOMETRIA).", "tipo": "recomendados"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "FORTALECER  JORNADAS DE SENSIBILIZACION SOBRE EL AUTOCUIDADO, LA HIGIENE Y LA PROTECCION DE ENFERMEDADES VISUALES , EXAMENES MEDICOS PERIODICOS (OPTOMETRIAS)", "tipo": "recomendados"}
        ]
    },
    { 
        "title": "biologico", 
        "text": "Biológico",
        "descripcion": "", 
        "funteDelPeligro": "Presencia de agentes  biológicos contaminantes (virus, bacterias) provenientes de la relación con los compañeros Y CLIENTE.", 
        "probabilidad": 0,
        "exposicion": 0,
        "efectosYOcurrencia": [
            { "descripcion": "", "title": "salud", "text": "Salud", "ejemplo": "INFECCIONES BACTERIANO RESPIRATORIOS O DIGESTIVOS, DERMATITIS . ENFERMEDADES LEVES CON INCAPACIDADES TEMPORALES", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "medio", "text": "Medio", "ejemplo": "PROPAGACIÓN DE VIRUS AL PERSONAL", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "produccion", "text": "Producción", "ejemplo": "GENERA CAMBIOS OPERATIVOS, Y REDISEÑO TEMPORAL", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "imagen", "text": "Imagen", "ejemplo": "SOLO ES DE CONOCIMIENTO DE PERSONAL DEL PROCESO", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "economico", "text": "Económico", "ejemplo": "LEVE", "requisitoLegal": "si", "consecuencia": "Number"}
        ],
        "controles": [
            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "MANTENIMIENTO DIARIO DE UNIDADES SANITARIAS.", "tipo": "existentes"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": "MANTENIMIENTO CONDICIONES DE HIGIENE EN PUESTOS DE TRABAJO.", "tipo": "existentes"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "", "tipo": "existentes"},

            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "MANTENER CONTROLES EXISTENTES.", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": "MANTENER CONDICIONES DE HIGIENE EN PUESTOS DE TRABAJO.", "tipo": "recomendados"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "DESARROLLAR JORANADAS DE SENSIBILIZACION SOBRE EL AUTOCUIDADO, LA HIGIENE Y LA PROTECCION DE ENFERMEDADES VIRALES, EVALUAR POSIBILIDAD DE REALIZAR VACUNACION CONTRA INFLUENZA ", "tipo": "recomendados"}
        ]
    },
    { 
        "title": "biomecanico", 
        "text": "Biomecánico",
        "descripcion": "", 
        "funteDelPeligro": "Postura sedente y Bípeda prolongada sin cambio de posición, trabajo con alta carga de movimiento repetitivo en miembro superior.", 
        "probabilidad": 0,
        "exposicion": 0,
        "efectosYOcurrencia": [
            { "descripcion": "", "title": "salud", "text": "Salud", "ejemplo": "LESIONES OSTEMUSCULARES (COLUMNA, ESPALDA, EN MIEMBRO SUPERIOR) , TENDINITIS, LUMBAGOS, HERNIA DISCAL,  ESGUINCES, LUXACIONES VARICES, AFECCIONES SISTEMA URINARIO, BURSITIS, FATIGA . LESIONES CON NECESIDAD DE INTERVENCION QUIRUGICA, ENFERMEDAD PROFESIONAL CON DISCAPACIDAD .", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "medio", "text": "Medio", "ejemplo": "No afecta", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "produccion", "text": "Producción", "ejemplo": "GENERA CAMBIOS OPERATIVOS Y REDISEÑO TEMPORAL, BAJO DESEMPEÑO EN LA LABOR, AUMENTO EN TASA DE ASUENTISMO Y ACCIDENTALIDAD. ABANDONO DEL CARGO, FENOMENOS DE RECARGA LABORAL ", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "imagen", "text": "Imagen", "ejemplo": "SI SOLO ES CONOCIMIENTO DE OTROS PROCESOS DE LA EMPRESA Y EL CLIENTE", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "economico", "text": "Económico", "ejemplo": "PERDIDA DE TIEMPO EFECTIVO DE TRABAJO, AL PAGAR POR INCAPACIDADES, SEGUROS, PERDIDA DE DINERO POR PARTE DEL TRABAJADOR EN TRAMITES Y TRANSPORTE DE LEGALIZACION", "requisitoLegal": "si", "consecuencia": "Number"}
        ],
        "controles": [
            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": " DESARROLLO DE EXAMENES MEDICO OCUPACIONALES OCN ENFASIS OSTEOMUSCULAR  PARA EL CONTROL Y DETECCION DE ALTERACIONES.  CAPACITACION PAUSAS ACTIVAS.", "tipo": "existentes"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "", "tipo": "existentes"},

            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": " MIRAR LA POSIBILIDAD DE REALIZAR ADECUACIONES EN EL PUESTO DE TRABAJO (ESCRITORIO ) YMANTENIMIENTO PREVENTIVO Y CORRECTIVO EN CUANTO A SILLETERIA (SOPORTE LUMBAR, SISTEMAS DE AJUSTE,  ESTABILIDAD Y COJINERIA)", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": "INSTAURAR SVE PARA PREVENCION DE DESORDENES MUSCULOESQUELETICOS,  Y EVALUAR L POSIBILIDAD DE REALIZAR ADECUACIONES A LOS PUESTOS DE TRABAJO PARA MINIZAR EL RIESGO.  ", "tipo": "recomendados"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "SENSIBILIZACION SOBRE AUTOCUIDADO , INSTAURAR PROGRAMA DE  PAUSAS ACTIVAS.", "tipo": "recomendados"}
        ]
    },
    { 
        "title": "psicosocial", 
        "text": "Psicosocial",
        "descripcion": "", 
        "funteDelPeligro": "EXIGENCIA DE RAPIDEZ EN LAS RESPUESTAS,   RECIBE GRAN CANTIDAD DE INFORMACIÓN (ESTÍMULOS) EN FORMA DISPERSA, VARIADA, CONTINUA (APREMIO DE TIEMPO),  REQUIERE ANÁLISIS DE GRAN CANTIDAD DE INFORMACIÓN EN POCO TIEMPO EN FORMA CONTÍNUA.", 
        "probabilidad": 0,
        "exposicion": 0,
        "efectosYOcurrencia": [
            { "descripcion": "", "title": "salud", "text": "Salud", "ejemplo": "ESTRÉS, FATIGA, DESMOTIVACIÓN, IRRITABILIDAD, TENSION,PORBLEMAS CARDIACOS, ULCERAS BAJO RENDIMIENTO, CEFALEA. ", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "medio", "text": "Medio", "ejemplo": "No afecta", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "produccion", "text": "Producción", "ejemplo": "GENERA CAMBIOS OPERATIVOS Y REDISEÑO TEMPORAL, BAJO DESEMPEÑO EN LA LABOR, AUMENTO EN TASA DE ASUENTISMO Y ACCIDENTALIDAD. ABANDONO DEL CARGO, FENOMENOS DE RECARGA LABORAL ", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "imagen", "text": "Imagen", "ejemplo": "SI SOLO ES CONOCIMIENTO DE OTROS PROCESOS DE LA EMPRESA Y EL CLIENTE", "requisitoLegal": "si", "consecuencia": "Number"},
            { "descripcion": "", "title": "economico", "text": "Económico", "ejemplo": "PERDIDA DE TIEMPO EFECTIVO DE TRABAJO, AL PAGAR POR INCAPACIDADES, SEGUROS, PERDIDA DE DINERO POR PARTE DEL TRABAJADOR EN TRAMITES Y TRANSPORTE DE LEGALIZACION.", "requisitoLegal": "si", "consecuencia": "Number"}
        ],
        "controles": [
            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "", "tipo": "existentes"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": "  INTEGRACION FIN DE AÑO, AMOR Y AMISTAD, DIA DE LA MUJER Y PRIMA  EXTRALEGAL SEMANA SANTA.", "tipo": "existentes"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "", "tipo": "existentes"},

            { "descripcion": "", "title": "eliminacion", "text": "Eliminación", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "sustitucion", "text": "Sustitución", "ejemplo": "", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlDeIngenieria", "text": "Control de ingeniería", "ejemplo": "IMPLEMENTAR SVE PARA RIESGO SICOLABORAL SEGÚN LO ESTIPULADO LEGALMENTE, EVALUAR, ALINEAR  Y TOMAR ACCIONES SOBRE ENCUESTAS DE CLIMA ORGANIZACIONAL GENERADAS POR CANAL DIRECTO .", "tipo": "recomendados"},
            { "descripcion": "", "title": "controlEnElMedio", "text": "Control en elmedio", "ejemplo": "INSTAURAR PROGRAMAS DE MOTIVACION Y BIENESTAR AL PERSONAL,  REALIZAR ACTIVIDADES DEPORTIVAS Y DE INTEGRACION, MANTENER   FIESTAS DE INTEGRACION.", "tipo": "recomendados"},
            { "descripcion": "", "title": "individuo", "text": "Individuo", "ejemplo": "DESARROLLAR ACTIVIDADES DE BIENESTAR, CAPACITACIONES E INDUCCION SOBRE EL CONTROL E LA TAREA, DESARROLLO DE ACTIVIDADES SE SENSIBILIZACION DEL REISGO, Y ACTIVIDADES DE MOTIVACIÓN Y PERMANENCIA EN LA EMPRESA.", "tipo": "recomendados"}
        ]
    },
    { 
        "title": "fisico", 
        "text": "Fisico",
        "descripcion": "Daños en la integridad fisica de la persona", 
        "funteDelPeligro": "Radiaciones no ionizantes provenientes de lamparas fluorescentes, fotocopiadora, y computador.", 
        "probabilidad": 0,
        "exposicion": 0,
        "tipo":["iluminacion", "ruido"]
        
    },
]
