// A. formulario sobre siniestros
const formSiniestros = {
  id: "A",
  indicadores: [
    {
      variableIndicador: 1.1,
      name: "v1_1",
      indicador: "Fatalidades",
      tipoDato: "real",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 1.2,
      name: "v1_2",
      indicador: "Heridos graves",
      tipoDato: "real",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 1.3,
      name: "v1_3",
      indicador: "Heridos leves",
      tipoDato: "real",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 1.4,
      name: "v1_4",
      indicador: "Choques simples",
      tipoDato: "real",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 1.5,
      name: "v1_5",
      indicador: "Kilómetros recorridos",
      tipoDato: "real",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 2.1,
      name: "v2_1",
      indicador: "Costos directos de fatalidades",
      tipoDato: "moneda",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 2.2,
      name: "v2_2",
      indicador: "Costos indirectos de fatalidades",
      tipoDato: "moneda",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 2.3,
      name: "v2_3",
      indicador: "Costos directos de heridos graves",
      tipoDato: "moneda",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 2.4,
      name: "v2_4",
      indicador: "Costos indirectos de heridos graves",
      tipoDato: "moneda",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 2.5,
      name: "v2_5",
      indicador: "Costos directos de heridos leves",
      tipoDato: "moneda",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 2.6,
      name: "v2_6",
      indicador: "Costos indirectos de heridos leves",
      tipoDato: "moneda",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 2.7,
      name: "v2_7",
      indicador: "Costos directos de choques simples",
      tipoDato: "moneda",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 2.8,
      name: "v2_8",
      indicador: "Costos indirectos de choques simples",
      tipoDato: "moneda",
      trimestral: true,
      value: "",
    },
  ],
  evidencias: [
    {
      id: 1,
      descripcion:
        "Registro de vehículos siniestrados, con mínimo los siguientes campos: Fecha del siniestro, nivel de pérdida (fatalidad, heridos graves, heridos leves o choque simple), número de personas implicadas por cada nivel, placa del vehículo implicado de la empresa, nombre del conductor implicado de la empresa, identificación del conductor implicado de la empresa, consecutivo del Informe Policial de Accidentes de Tránsito (IPAT) en el RNAT del RUNT, nombre del organismo de tránsito o autoridad que elaboró el IPAT, fecha del comité donde fue analizado",
      tipoEvidencia: "Archivo Excel",
      maxSize: "50 Mb",
      trimestral: false,
    },
    {
      id: 2,
      descripcion:
        "Copia de las actas de reunión del comité PESV, donde conste la investigación interna de los siniestros viales",
      tipoEvidencia: "Archivo PDF",
      maxSize: "15 Mb",
      trimestral: true,
    },
    {
      id: 3,
      descripcion:
        "Evidencia de la divulgación de las lecciones aprendidas diferentes a capacitaciones y certificación del área de RRHH, donde conste la retroalimentación de los implicados",
      tipoEvidencia: "Archivo PDF",
      maxSize: "15 Mb",
      trimestral: true,
    },
    {
      id: 4,
      descripcion:
        "Certificación suscrita por el Representante Legal de la señalización, puestos de control, gastos de GPS, etc, implementados para reducir la accidentalidad",
      tipoEvidencia: "Archivo PDF",
      maxSize: "15 Mb",
      trimestral: true,
    },
    {
      id: 5,
      descripcion:
        "Detalle de cuentas a nivel de terceros de los costos directos e indirectos ocasionados por los accidentes (reparaciones, demandas pagas, etc.) con mínimo los siguientes campos: Fecha movimiento contable, código cuenta, número de comprobante, nombre e identificación del tercero, detalle o concepto, saldo inicial, movimiento débito, movimiento crédito, saldo final",
      tipoEvidencia: "Archivo Excel",
      maxSize: "50 Mb",
      trimestral: true,
    },
    {
      id: 6,
      descripcion:
        "Copia del informe jurídico de los procesos en contra de la empresa por todo tipo de siniestros viales, incluyendo accidentes derivados de desplazamientos laborales y monto del fondo para accidentes (si existe)",
      tipoEvidencia: "Archivo PDF",
      maxSize: "15 Mb",
      trimestral: true,
    },
    {
      id: 7,
      descripcion:
        "Detalle de las provisiones derivadas de las demandas por accidentes, con mínimo los siguientes datos: Código cuenta, código interno o numeración del proceso, fecha de demanda, descripción de la demanda, probabilidad de pérdida",
      tipoEvidencia: "Archivo Excel",
      maxSize: "50 Mb",
      trimestral: true,
    },
  ],
};

// B. formulario administrativo
const formAdministrativo = {
  id: "B",
  indicadores: [
    {
      variableIndicador: 4.1,
      name: "v4_1",
      indicador: "Número total de metas definidas PESV por trimestre",
      tipoDato: "entero",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 4.2,
      name: "v4_2",
      indicador:
        "Número de metas alcanzadas o logradas en el PESV por trimestre",
      tipoDato: "entero",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 5.1,
      name: "v5_1",
      indicador:
        "Actividades programadas a partir del plan anual de trabajo PESV",
      tipoDato: "entero",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 5.2,
      name: "v5_2",
      indicador:
        "Actividades ejecutadas a partir del plan anual de trabajo PESV",
      tipoDato: "entero",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 11.1,
      name: "v11_1",
      indicador: "Capacitaciones en seguridad vial programadas",
      tipoDato: "entero",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 11.2,
      name: "v11_2",
      indicador: "Capacitaciones en seguridad vial ejecutadas",
      tipoDato: "entero",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 12.1,
      name: "v12_1",
      indicador: "Colaboradores capacitados en seguridad vial",
      tipoDato: "entero",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 12.2,
      name: "v12_2",
      indicador: "Colaboradores de la organización",
      tipoDato: "entero",
      trimestral: true,
      value: "",
    },
  ],
  evidencias: [
    {
      id: 1,
      descripcion:
        "Copia del informe presentado trimestralmente por el lider PESV al comité sobre la ejecución de los planes y los avances respecto a las metas",
      tipoEvidencia: "Archivo PDF",
      maxSize: "15 Mb",
      trimestral: true,
    },
    {
      id: 2,
      descripcion:
        "Certificación suscrita por el Contador y Revisor Fiscal (si aplica) de los recursos empleados trimestralmente para el desarrollo del plan anual de trabajo PESV, con el detalle de la destinación de los mismos",
      tipoEvidencia: "Archivo PDF",
      maxSize: "15 Mb",
      trimestral: true,
    },
    {
      id: 3,
      descripcion:
        "Listado unificado de asistentes a capacitaciones, con mínimo los siguientes campos: nombre de los asistentes, identificación, fecha capacitación y tematica dictada",
      tipoEvidencia: "Archivo Excel",
      maxSize: "50 Mb",
      trimestral: true,
    },
    {
      id: 4,
      descripcion:
        "Evidencias (listados de asistencia, imágenes, videos, etc.) de las actividades de capacitación adelantadas a los conductores",
      tipoEvidencia: "Archivo Comprimido",
      maxSize: "50 Mb",
      trimestral: true,
    },
    {
      id: 5,
      descripcion:
        "Listado unificado de colaboradores de la organización, con mínimo los siguientes campos: nombre, identificación, teléfono, correo electrónico y cargo",
      tipoEvidencia: "Archivo Excel",
      maxSize: "50 Mb",
      trimestral: true,
    },
  ],
};

// C. formulario de funcionamiento
const formFuncionamiento = {
  id: "C",
  indicadores: [
    {
      variableIndicador: 6.1,
      name: "v6_1",
      indicador:
        "Número de excesos en la jornada diaria de trabajo de los conductores (eventos en los que los conductores han superado el tiempo máximo permitido en la legislación) por mes",
      tipoDato: "entero",
      trimestral: false,
      value: "",
    },
    {
      variableIndicador: 6.2,
      name: "v6_2",
      indicador: "Total de días trabajados por todos los conductores",
      tipoDato: "entero",
      trimestral: false,
      value: "",
    },
    {
      variableIndicador: 8.1,
      name: "v8_1",
      indicador:
        "Número diario de desplazamientos laborales con exceso de velocidad (casos en los que se superó el límite definido por la organización) por mes",
      tipoDato: "entero",
      trimestral: false,
      value: "",
    },
    {
      variableIndicador: 8.2,
      name: "v8_2",
      indicador: "Número total de desplazamientos laborales",
      tipoDato: "entero",
      trimestral: false,
      value: "",
    },
  ],
  evidencias: [
    {
      id: 1,
      descripcion:
        "Certificación del Representante Legal donde consten los límites de velocidad definidos para desplazamientos laborales en las diferentes sedes que posee la organización, desagregados por departamento, ciudad y recorrido (origen-destino)",
      tipoEvidencia: "Archivo PDF",
      maxSize: "15 Mb",
      trimestral: false,
    },
    {
      id: 2,
      descripcion:
        "Copia del informe mensual presentado por el lider del PESV al comité sobre el total de desplazamientos laborales realizados, las multas impuestas por exceso de velocidad, llamados de atención dentro de las instalaciones y las mediciones realizadas",
      tipoEvidencia: "Archivo PDF",
      maxSize: "15 Mb",
      trimestral: false,
    },
    {
      id: 3,
      descripcion:
        "Listado de rutas de desplazamientos laborales con mínimo los siguientes campos: origen y destino según Divipol a nivel de municipios, solicitando registrar el punto, vereda o barrio de referencia; así como los kilómetros de cada ruta registrada y la frecuencia de uso trimestral por cada ruta",
      tipoEvidencia: "Archivo Excel",
      maxSize: "50 Mb",
      trimestral: false,
    },
    {
      id: 4,
      descripcion:
        "Control horario aplicado a todo el personal con la siguiente información: nombre completo, identificación, cargo, tipo de vinculación (contrato directo, prestación de servicios u honorarios, outsourcing, proveedor, practicante), rol (administrativo / operativo), fecha de medición, hora entrada, hora salida",
      tipoEvidencia: "Archivo Excel",
      maxSize: "50 Mb",
      trimestral: true,
    },
  ],
};

// D. formulario de vehiculos
const formVehiculos = {
  id: "D",
  indicadores: [
    {
      variableIndicador: 7.1,
      name: "v7_1",
      indicador:
        "Vehículos propios incluidos en el programa de gestión de la velocidad",
      tipoDato: "entero",
      trimestral: false,
      value: "",
    },
    {
      variableIndicador: 7.2,
      name: "v7_2",
      indicador:
        "Número de vehículos propios utilizados para desplazamientos laborales en el mes",
      tipoDato: "entero",
      trimestral: false,
      value: "",
    },
    {
      variableIndicador: 7.3,
      name: "v7_3",
      indicador:
        "Vehículos de contratistas incluidos en el programa de gestión de la velocidad",
      tipoDato: "entero",
      trimestral: false,
      value: "",
    },
    {
      variableIndicador: 7.4,
      name: "v7_4",
      indicador:
        "Número de vehículos de contratistas utilizados para desplazamientos laborales en el mes",
      tipoDato: "entero",
      trimestral: false,
      value: "",
    },
    {
      variableIndicador: 7.5,
      name: "v7_5",
      indicador:
        "Vehículos de terceros incluidos en el programa de gestión de la velocidad",
      tipoDato: "entero",
      trimestral: false,
      value: "",
    },
    {
      variableIndicador: 7.6,
      name: "v7_6",
      indicador:
        "Número de vehículos de terceros utilizados para desplazamientos laborales en el mes",
      tipoDato: "entero",
      trimestral: false,
      value: "",
    },
    {
      variableIndicador: 9.1,
      name: "v9_1",
      indicador: "Número de vehículos inspeccionados diariamente",
      tipoDato: "entero",
      trimestral: false,
      value: "",
    },
    {
      variableIndicador: 9.2,
      name: "v9_2",
      indicador: "Número de vehículos que operan diariamente",
      tipoDato: "entero",
      trimestral: false,
      value: "",
    },
    {
      variableIndicador: 10.1,
      name: "v10_1",
      indicador:
        "Numero total de actividades de mantenimiento preventivo programadas por trimestre",
      tipoDato: "porcentaje",
      trimestral: true,
      value: "",
    },
    {
      variableIndicador: 10.2,
      name: "v10_2",
      indicador:
        "Numero de actividades de mantenimiento preventivo ejecutadas por trimestre",
      tipoDato: "porcentaje",
      trimestral: true,
      value: "",
    },
  ],
  evidencias: [
    {
      id: 1,
      descripcion:
        "Copia del informe mensual presentado por el lider del PESV al comité sobre el programa de gestión de velocidad y las mediciones realizadas",
      tipoEvidencia: "Archivo PDF",
      maxSize: "15 Mb",
      trimestral: false,
    },
    {
      id: 2,
      name: "e_2",
      descripcion:
        "Cantidad de desplazamientos laborales realizados en vehículos",
      tipoEvidencia: "Numéricos Entero",
      trimestral: false,
      value: "",
    },
    {
      id: 3,
      name: "e_3",
      descripcion:
        "Cantidad de desplazamientos laborales realizados en vehículos que fueron medidos",
      tipoEvidencia: "Numéricos Entero",
      trimestral: false,
      value: "",
    },
    {
      id: 4,
      descripcion:
        "Relación de infracciones por tipo o código de infracción a las normas de tránsito de los conductores de la organización, que contenga como mínimo: fecha, nombre del conductor, identificación del conductor, placa del vehículo, código por el cual se impuso la multa, departamento y ciudad de la infracción, organismo o autoridad que impuso la multa, estado del proceso contravencional o pago de la multa",
      tipoEvidencia: "Archivo Excel",
      maxSize: "50 Mb",
      trimestral: false,
    },
    {
      id: 5,
      name: "e_5",
      descripcion: "Llamados de atención dentro de las instalaciones",
      tipoEvidencia: "Numéricos Entero",
      trimestral: false,
      value: "",
    },
    {
      id: 6,
      descripcion:
        "Relación Excel de los vehículos a los cuales fue realizada la inspección preoperativa, con mínimo los siguientes datos: fecha, placa, conductor, identificación, persona que realizó la verificación por parte de la empresa, identificación y cargo, aprobó o no aprobó",
      tipoEvidencia: "Archivo Excel",
      maxSize: "50 Mb",
      trimestral: false,
    },
    {
      id: 7,
      descripcion:
        "Copia de los formatos empleados para las inspecciones diarias de vehículos, seleccionados en forma aleatoria para el 10% del parque automotor, siempre y cuando sea mayor a 10 vehículos",
      tipoEvidencia: "Archivo PDF",
      maxSize: "50 Mb",
      trimestral: false,
    },
    {
      id: 8,
      name: "e_8",
      descripcion: "Cantidad de vehículos que reportaron fallas durante el mes",
      tipoEvidencia: "Numéricos Entero",
      trimestral: false,
      value: "",
    },
    {
      id: 9,
      descripcion:
        "Relación de mantenimientos preventivos ejecutados para el 10% del parque automotor, seleccionado en forma aleatoria, siempre y cuando sea mayor a 10 vehículos, con mínimo los siguientes datos: fecha, placa, tipo de mantenimiento (preventivo / correctivo), establecimiento o centro especializado donde se realizó la intervención, NIT, nombre de la persona que realizó el mantenimiento, identificación y rol (conductor / mecánico / Ing. Mecánico)",
      tipoEvidencia: "Archivo Excel",
      maxSize: "50 Mb",
      trimestral: true,
    },
  ],
};

export default {
  formSiniestros,
  formAdministrativo,
  formFuncionamiento,
  formVehiculos,
};
