import data from "./revisionPeriodicaVehiculo";

export default {
  state: {
    documentacion: null,
    OrdenYAseo: null,
    carroceria: null,
    cabina: null,
    motor: null,
    herramientas: null,
    kitAmbiental: null,
  },
  mutations: {
    initInspeccionVehiculo(state) {
      state.documentacion = data.documentacion;
      state.OrdenYAseo = data.OrdenYAseo;
      state.carroceria = data.carroceria;
      state.cabina = data.cabina;
      state.motor = data.motor;
      state.herramientas = data.herramientas;
      state.kitAmbiental = data.kitAmbiental;
    },
  },
  actions: {
    initInspeccionVehiculo({ commit }) {
      commit("initInspeccionVehiculo");
    },
  },
  getters: {},
};
